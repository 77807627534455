import { appConstants } from "redux/constants";
import { db } from "utils/firebase";

export const getFaculties = () => {
  return async dispatch => {
    try{
      dispatch({type: appConstants.GET_FACULTIES_REQUEST});      
      let payload = [];
      db.collection("faculty")
        .onSnapshot(snapshot=>{
          payload = snapshot.docs.map((doc)=>({id: doc.id, ...doc.data()}))                                            
          dispatch({type: appConstants.GET_FACULTIES_SUCCESS, payload});
        })        
    }catch(error){
      dispatch({
        type: appConstants.GET_FACULTIES_FAILURE,
        payload: "Something went wrong while fetching Data"
      })
    }
  }
}

export const getEntranceExams = () => {
  return async dispatch => {
    try{
      dispatch({type: appConstants.GET_ENTRANCE_EXAMS_REQUEST});      
      let payload = [];
      db.collection("entranceExams")
        .orderBy("startDate", "desc")
        .onSnapshot(snapshot=>{
          payload = snapshot.docs.map((doc)=>({id: doc.id, ...doc.data()}))                                            
          dispatch({type: appConstants.GET_ENTRANCE_EXAMS_SUCCESS, payload});
        })        
    }catch(error){
      dispatch({
        type: appConstants.GET_ENTRANCE_EXAMS_FAILURE,
        payload: "Something went wrong while fetching Data"
      })
    }
  }
}

export const getExamForms = () => {
  return async dispatch => {
    try{
      dispatch({type: appConstants.GET_EXAM_FORMS_REQUEST});      
      let payload = [];
      db.collection("exam-form")
        .orderBy("startDate", "desc")
        .onSnapshot(snapshot=>{
          payload = snapshot.docs.map((doc)=>({id: doc.id, ...doc.data()}))                                            
          dispatch({type: appConstants.GET_EXAM_FORMS_SUCCESS, payload});
        })        
    }catch(error){
      dispatch({
        type: appConstants.GET_EXAM_FORMS_FAILURE,
        payload: "Something went wrong while fetching Data"
      })
    }
  }
}

export const getExamResults = () => {
  return async dispatch => {
    try{
      dispatch({type: appConstants.GET_EXAM_RESULTS_REQUEST});      
      let payload = [];
      db.collection("results")        
        .onSnapshot(snapshot=>{
          payload = snapshot.docs.map((doc)=>({id: doc.id, ...doc.data()}))                                            
          dispatch({type: appConstants.GET_EXAM_RESULTS_SUCCESS, payload});
        })        
    }catch(error){
      dispatch({
        type: appConstants.GET_EXAM_RESULTS_FAILURE,
        payload: "Something went wrong while fetching Data"
      })
    }
  }
}

export const getImportantLinks = () => {
  return async dispatch => {
    try{
      dispatch({type: appConstants.GET_IMPORTANT_LINKS_REQUEST});      
      let payload = [];
      db.collection("importantLink")
        .orderBy("index", "asc")        
        .onSnapshot(snapshot=>{
          payload = snapshot.docs.map((doc)=>({id: doc.id, ...doc.data()}))                                            
          dispatch({type: appConstants.GET_IMPORTANT_LINKS_SUCCESS, payload});
        })        
    }catch(error){
      dispatch({
        type: appConstants.GET_IMPORTANT_LINKS_FAILURE,
        payload: "Something went wrong while fetching Data"
      })
    }
  }
}