import { Layout } from "organisms";
import { AdmissionsPage } from "templates";

const Admissions = () => {

  document.title = "Admissions | ADMIN SSJU";

  return (
    <Layout>
      <AdmissionsPage/>
    </Layout>
  )
}

export default Admissions