import React from "react";

import { Box } from "atoms";
import { SidebarOption } from "molecules";
import { sideNav } from "./data";

export const Sidebar = () => {

  const user = null;

  return (
    <Box
      flex={{xm: "1"}}
      position="sticky"
      top="5rem"
      overflowY="auto"
      height="calc(100vh - 8rem)"
      bg="white"
      borderRight="2px solid"
      borderColor="whitesmoke"      
      minWidth={{xs: "fit-content"}}            
    >  
      <Box
        p="1.5rem 1rem"
      >               
        {sideNav.map((item, index) => (          
          <SidebarOption
            key={index}
            Icon={item.Icon}
            title={item.title}
            to={item.to}
            description={item.description}
          />       
        ))}
      </Box>     
    </Box>
  )
}