import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { BiDetail } from "react-icons/bi";
import { IoMdLink } from "react-icons/io";
import { MdOutlineDelete, MdOutlineTitle } from "react-icons/md";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, InputSelect, Loader, Text } from "atoms";
import { db } from "utils/firebase";
import { getFirebaseTimestamp, uploadImageToFirebase } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"),  
});

export const RecruitmentsPage = () => {

  const [ loading, setLoading ] = useState(false);
  const [ image, setImage ] = useState(null);  
  const [ error, setError ] = useState(null); 

  useEffect(() => {
    if(error){
      toast.error(error);
      setError(null);
    }       
  }, [loading, error]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true); 
    let link = "";
    if(image){
      link = await uploadImageToFirebase(image, "images/recruitments");
    }else{
      toast.error("Image Required");
      setLoading(false);
      return;
    }
    let career = {
      title: values.title,
      desc: values.desc,
      link,      
      timestamp: getFirebaseTimestamp(),
    }
    for(let i = 0; i < values.src.length; i++){
      if(i === 0){
        career["newsSrc"] = true;
        career["srcTitle"] = values.src[i].srcTitle;
        career["srcFileUrl"] = values.src[i].srcUrl;
      }else{
        career["newsSrc"+String(i+1)] = true;
        career["srcTitle"+String(i+1)] = values.src[i].srcTitle;
        career["srcFileUrl"+String(i+1)] = values.src[i].srcUrl;
      }
    }
    db.collection("recruitment").add(career);
    toast.success("rRecruitment Added Successfully!");
    setImage(null);
    resetForm();
    setLoading(false);
    setSubmitting(false);          
  }

  return (
    <Box
      width="100%"
      bg="white"
      p="2rem"
      borderRadius="0.5rem"
    >
      {loading && <Loader />}
      <Text
        fontSize="1.8rem"
        fontWeight="600"
        mb="1rem"
        textTransform="uppercase"
        color="primary.200"
      >
        Add Recruitment
      </Text>
      <Box my="2rem">
        <Formik
          initialValues={{              
            title: "",
            desc: "",            
            src: []            
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue }) => {
            return (                  
              <Form>  
                <Flex
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <Box flex="2">
                    <Input
                      name="title"
                      type="text"
                      label="Recruitment Title*"                        
                      id="title"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.title ? "danger.100" : "primary.200"}
                      touched={touched.title}
                      errors={errors.title}                            
                    />                  
                    <Input
                      name="desc"
                      type="text"                    
                      label="Recruitment Description*"
                      id="desc"
                      icon={<BiDetail />}
                      iconColor={errors.desc ? "danger.100" : "primary.200"}
                      touched={touched.desc}
                      errors={errors.desc}
                      textArea
                      rows={4}
                    />
                  </Box>
                  <Box flex="1" ml={{md: "1rem"}} mt="1.6rem">
                    {image &&                         
                      <Image 
                        src={URL.createObjectURL(image)} 
                        width="fit-content" 
                        height="18rem" 
                        objectFit="contain"  
                        borderRadius="0.5rem"                           
                        style={{border: "2px solid whitesmoke", margin: "0 auto", marginBottom: "1rem"}}
                      />}
                    <input
                      type="file"
                      accept="image/*"
                      id="image"
                      name="image"
                      style={{ display: "none" }}
                      onChange={(e)=> setImage(e.target.files[0])}
                    />
                    <Button
                      as="label"
                      htmlFor="image"
                      variant="info"
                      width="fit-content"
                      mx="auto"
                      p="1rem 2rem"
                      borderRadius="0.5rem"                       
                    >
                      Pick Image
                    </Button>
                  </Box>
                </Flex> 
                <Button
                  mb="2rem"
                  width="fit-content"                  
                  p="1rem 2rem"
                  borderRadius="0.5rem"
                  type="button"
                  variant="success"
                  onClick={() => {
                    if(values.src.length < 4){
                      setFieldValue("src", [...values.src, { srcTitle: "", srcUrl: "" }]);
                    }
                  }}
                >
                  Add Src
                </Button>                                  
                <FieldArray
                  name="src"
                  render={(arrayHelpers) => (
                    <Box>
                      {values.src.map((src, index) => (
                        <Flex
                          key={index}
                          flexDirection={{ xs: "column", md: "row" }}
                          mb="1rem"
                        >
                          <Box mr={{md: "2rem"}}>
                            <Input
                              name={`src.${index}.srcTitle`}
                              type="text"
                              label={"Source Title " + (index+1) + "*"}
                              id={`src.${index}.srcTitle`}
                              icon={<MdOutlineTitle />}
                              iconColor={errors.src ? "danger.100" : "primary.200"}
                              touched={touched.src}
                              errors={errors.src}                                                                
                            />
                          </Box>
                          <Box mr={{md: "2rem"}}>
                            <Input
                              name={`src.${index}.srcUrl`}
                              type="text"
                              label={"Source URL " + (index+1) + "*"}
                              id={`src.${index}.srcUrl`}
                              icon={<IoMdLink />}
                              iconColor={errors.src ? "danger.100" : "primary.200"}
                              touched={touched.src}
                              errors={errors.src}                                                                
                            />
                          </Box>                      
                          <Box
                            width="fit-content"
                            p="1rem 2rem"
                            borderRadius="0.5rem"
                            type="button"                          
                            onClick={() => arrayHelpers.remove(index)}
                            color="danger.100"
                            mt={{md: "1.5rem"}}
                            cursor="pointer"
                            mx={{xs: "auto", md: "0"}}
                          >
                            <MdOutlineDelete
                              fontSize="2rem"
                            />
                          </Box>                      
                        </Flex>
                      ))}
                    </Box>
                  )}
                />                  

                <Button
                  variant="primary"                    
                  px="2rem"
                  py="1rem"
                  type="submit"                    
                  mt="1rem"                                       
                  loading={loading}
                  textTransform="uppercase"
                  borderRadius="0.5rem"
                  width="fit-content"
                >
                  <Text fontSize="1.6rem" fontWeight="500">
                    Add Recruitment
                  </Text>
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}