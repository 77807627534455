export const appConstants = {
  GET_FACULTIES_REQUEST: "GET_FACULTIES_REQUEST",
  GET_FACULTIES_SUCCESS: "GET_FACULTIES_SUCCESS",
  GET_FACULTIES_FAILURE: "GET_FACULTIES_FAILURE",
  GET_ENTRANCE_EXAMS_REQUEST: "GET_ENTRANCE_EXAMS_REQUEST",
  GET_ENTRANCE_EXAMS_SUCCESS: "GET_ENTRANCE_EXAMS_SUCCESS",
  GET_ENTRANCE_EXAMS_FAILURE: "GET_ENTRANCE_EXAMS_FAILURE",
  GET_EXAM_FORMS_REQUEST: "GET_EXAM_FORMS_REQUEST",
  GET_EXAM_FORMS_SUCCESS: "GET_EXAM_FORMS_SUCCESS",
  GET_EXAM_FORMS_FAILURE: "GET_EXAM_FORMS_FAILURE",
  GET_EXAM_RESULTS_REQUEST: "GET_EXAM_RESULTS_REQUEST",
  GET_EXAM_RESULTS_SUCCESS: "GET_EXAM_RESULTS_SUCCESS",
  GET_EXAM_RESULTS_FAILURE: "GET_EXAM_RESULTS_FAILURE",
  GET_IMPORTANT_LINKS_REQUEST: "GET_IMPORTANT_LINKS_REQUEST",
  GET_IMPORTANT_LINKS_SUCCESS: "GET_IMPORTANT_LINKS_SUCCESS",
  GET_IMPORTANT_LINKS_FAILURE: "GET_IMPORTANT_LINKS_FAILURE",  
}