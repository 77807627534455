import React, { useState } from "react";

import { Box, Text } from "atoms";

export const HeaderOption = ({
  Icon,
  badge,
  text,
  onClick
}) => {

  const [ hover, setHover ] = useState(false);

  return (
    <Box      
      position="relative"
      mx="0.8rem"
      cursor="pointer"
      color="grey.400"
      fontSize="2.2rem"
      onClick={onClick}
      pt="0.8rem"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}      
    >
      {Icon}
      {
        badge && <Text
          as="span"
          position="absolute"
          top="0.4rem"
          right="-0.5rem"
          bg="danger.500"
          color="white"
          fontSize="0.8rem"
          borderRadius="50%"
          p="0.2rem 0.5rem"
          fontWeight="600"
        >
          {badge}
        </Text>
      }
      {
        hover && <Box
          position="absolute"
          top="100%"
          left="50%"
          transform="translate(-50%, 0)"
          bg="black"
          color="white"
          borderRadius="0.2rem"
          p="0.6rem"
          fontSize="1rem"
          fontWeight="500"   
          transition="all 0.4s ease-in-out"           
        >          
          <Box
            position="absolute"
            top="-0.5rem"
            left="50%"
            transform="translate(-50%, 0)"
            width="0"
            height="0"
            borderLeft="0.5rem solid transparent"
            borderRight="0.5rem solid transparent"
            borderBottom="0.5rem solid black"            
          />
          {text}
        </Box>
      }      
    </Box>
  )
}