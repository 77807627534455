import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD72LvdJi9MwlmC0zip-11-EP3x-27T1og",
  authDomain: "ssju-556a2.firebaseapp.com",
  projectId: "ssju-556a2",
  storageBucket: "ssju-556a2.appspot.com",
  messagingSenderId: "680802920864",
  appId: "1:680802920864:web:d96763b7212f3d6bbb1cc5",
  measurementId: "G-VZ2C2SB0ER"
};

const firebaseTestConfig = {
  apiKey: "AIzaSyChqFaJtJx53UU1a_hh1rIfn1KSB9f7Um0",
  authDomain: "ssjutest.firebaseapp.com",
  projectId: "ssjutest",
  storageBucket: "ssjutest.appspot.com",
  messagingSenderId: "107802323385",
  appId: "1:107802323385:web:fb1a04523f06eb3f1bae68",
  measurementId: "G-BSVN6PYXDH"
};

let firebaseApp
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
}else {
  firebaseApp = firebase.app();
}

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export default firebase;
export {  db, auth, storage };