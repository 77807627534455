import { Layout } from "organisms";
import { ExamResultsPage } from "templates";

const ExamResults = () => {

  document.title = "Exam Results | ADMIN SSJU";

  return (
    <Layout>
      <ExamResultsPage/>
    </Layout>
  )
}

export default ExamResults