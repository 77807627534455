import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { AiOutlineLock, AiOutlineMail } from "react-icons/ai";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, Loader, Text } from "atoms";
import { auth } from "utils/firebase";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid format")
    .required("Email Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),
  password: Yup.string()    
    .required("Password Required")
    .min(6, "Min 6 characters required"),    
});

export const LoginPage = () => {

  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null); 
  
  useEffect(() => {
    if(error){
      toast.error(error);
      setError(null);
    }       
  }, [loading, error]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);        
    auth.signInWithEmailAndPassword(values.email, values.password)
      .then((userCredential) => {
        setLoading(false);
        console.log(userCredential.user)                
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setError(errorMessage);
        setLoading(false);
        values.password = "";
      });        
    setSubmitting(false);          
  }

  return (
    <Flex
      width="100%"
      height="100vh"
    >     
      {loading && <Loader/>} 
      <Box
        flex={{xs: "1", xm: "2"}}        
        display={{ xs: "none", xm: "flex"}}
        alignItems="center"
        justifyContent="center"
        bg="black"
      >
          <Image
            src="/assets/whiteTextLogo.svg"
            alt="logo"
            width="50rem"
            objectFit="contain"
          />
      </Box>
      <Flex
        flex="1"        
        flexDirection="column"
        alignItems="center"
        p="2rem"
        justifyContent="space-between"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          width="100%"
        >          
          <Image
            src="/favicon-main.png"
            alt="logo"
            width="10rem"
            objectFit="contain"
          />
          <Box my="2rem" width={{xs: "80%", md: "60%", xm: "80%"}}>
            <Formik
              initialValues={{              
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ touched, errors, values }) => {
                return (                  
                  <Form>                  
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email Address*"
                      id="email"
                      icon={<AiOutlineMail />}
                      iconColor={errors.email ? "danger.100" : "primary.200"}
                      touched={touched.email}
                      errors={errors.email}                            
                    />
                    <Input
                      name="password"
                      type="password"
                      placeholder="Password*"
                      id="password"
                      icon={<AiOutlineLock />}
                      iconColor={errors.password ? "danger.100" : "primary.200"}
                      touched={touched.password}
                      errors={errors.password}
                    />                       

                    <Button
                      variant="primary"                    
                      px="2rem"
                      py="1rem"
                      type="submit"
                      ml="auto"
                      mt="1.5rem"                                       
                      loading={loading}
                      textTransform="uppercase"
                      borderRadius="0.5rem"
                    >
                      <Text fontSize="1.6rem" fontWeight="500">
                        Login
                      </Text>
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </Flex>
        <Box my="1rem">          
          <Text textAlign="center">
            SSJU
          </Text>
          <Text textAlign="center">
            Copyright &copy; 2022
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}