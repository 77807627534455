import { Layout } from "organisms";
import { FacultiesPage } from "templates";

const Faculties = () => {

  document.title = "Faculties | ADMIN SSJU";

  return (
    <Layout>
      <FacultiesPage/>
    </Layout>
  )
}

export default Faculties