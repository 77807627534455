import { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { BiLinkAlt } from "react-icons/bi";
import { BsCardImage } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { ImListNumbered } from "react-icons/im";
import { MdDeleteOutline, MdOutlineDelete, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FormControlLabel, Switch } from "@material-ui/core";

import { Box, Button, Flex, Grid, Image, Input, Loader, Text } from "atoms";
import { DeleteConfirmationModal } from "molecules";
import { getImportantLinks } from "redux/actions";
import { db } from "utils/firebase";

const validationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(2, "Title must be at least 2 characters") ,
  img: Yup.string()
    .required("Image is required")
    .min(2, "Image must be at least 2 characters") ,
  index: Yup.number()
    .required("Index is required")    
});

export const ImportantLinksPage = () => {

  const [ loading, setLoading ] = useState(false);    
  const [ impLink, setImpLink ] = useState(null);
  const [ editMode, setEditMode ] = useState(false);
  const [ forDelete, setForDelete ] = useState(null);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);

  const dispatch = useDispatch();
  const { importantLinks, loading: reducerLoading } = useSelector(state => state.app);

  useEffect(() => {
    if(importantLinks.length === 0)  dispatch(getImportantLinks());    
  }, [dispatch]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);    
    let link = {
      title: values.title,  
      index: parseInt(values.index),
      img: values.img,
    }         
    if(values.links.length > 0) link.links = values.links;        
    if(editMode){
      await db.collection("importantLink").doc(impLink.id).update(link);
      toast.success("Important Link Updated Successfully!");
    }else{      
      await db.collection("importantLink")        
        .add(link)
        .then((docRef) => {
          toast.success("Important Link Added successfully!");          
        })
        .catch((error) => {          
          toast.error("Something went wrong while adding exam");
        });
    }    
    setImpLink(null);
    setEditMode(false);
    resetForm();
    setLoading(false);
    setSubmitting(false);          
  }

  const onDeleteExamResult = async () => {
    setDeleteModalOpen(false);
    setLoading(true);
    await db.collection("importantLink").doc(forDelete.id).delete();
    toast.success("Important Link Deleted Successfully!");
    setForDelete(null);    
    setLoading(false);
  }

  return (
    <>
      {deleteModalOpen && 
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          data={{
            name: forDelete.title,
            module: "Important Link",
          }}            
          onRequestClose={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          ariaHideApp={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          onConfirm={() => onDeleteExamResult()}
        />
      }
      {(loading || reducerLoading) && <Loader />}

      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
        id="form"
      >        
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="1.8rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
            color="primary.200"
          >
            {editMode ? "Update" : "Add"} Important Link Section
          </Text>          
        </Flex> 

        <Box my="2rem">
          <Formik
            initialValues={{              
              title: editMode ? impLink?.title : "",    
              index: editMode ? impLink?.index : "",    
              img: editMode ? impLink?.img : "",              
              links: editMode ? impLink?.links || [] : [],                            
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ touched, errors, setFieldValue, values, resetForm }) => {
              return (                  
                <Form>  
                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                  >
                    <Box mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="title"
                        type="text"
                        label="Section Title*"                        
                        id="title"
                        icon={<MdOutlineTitle />}
                        iconColor={errors.title ? "danger.100" : "primary.200"}
                        touched={touched.title}
                        errors={errors.title}                            
                      />   
                    </Box> 
                    <Box mr={{xs: "0", md: "2rem"}} mt={{xs: "2rem", md: "0"}} flex="0.5">
                      <Input
                        name="index"
                        type="number"
                        label="Section Index*"                        
                        id="index"
                        icon={<ImListNumbered />}
                        iconColor={errors.index ? "danger.100" : "primary.200"}
                        touched={touched.index}
                        errors={errors.index}                            
                      />   
                    </Box> 
                    <Box mt={{xs: "2rem", md: "0"}} flex="1">
                      <Input
                        name="img"
                        type="text"
                        label="Section Image*"                        
                        id="img"
                        icon={<BsCardImage />}
                        iconColor={errors.img ? "danger.100" : "primary.200"}
                        touched={touched.img}
                        errors={errors.img}                            
                      />   
                    </Box>                                          
                  </Flex>                       
                  <Flex alignItems="start" mb="2rem">
                    {values.img !== "" && (
                      <Box mr="3rem" border="1px solid whitesmoke" p="1rem" borderRadius="0.4rem">
                        <Image
                          src={values.img}
                          alt="Section Image"
                          width="4rem"
                          height="4rem"
                        />
                      </Box>
                    )}                    
                    <Box>
                      <Button
                        type="button"
                        variant="primary"
                        onClick={() => setFieldValue("links", [...values.links, {title: "", href: "", new: false, ext: false, index: "" }])}
                        p="1rem 2rem"
                        borderRadius="0.5rem"
                        width="fit-content"
                        mt="1.5rem"
                      >
                        Add Links
                      </Button>
                    </Box>                   
                  </Flex>                                                                                                                                         
                  <Box                                        
                    border="1px solid #e2e8f0"
                    p="2rem 1rem"
                    borderRadius="0.5rem"
                  >
                    <FieldArray
                      name="links"
                      render={(arrayHelpers) => (
                        <Flex
                          flexWrap="wrap"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {values.links.map((link, index) => (
                            <Flex
                              key={index}
                              flexDirection={{ xs: "column", md: "row" }}
                              mb="1rem"                              
                            >
                              <Box mr={{md: "2rem"}} flex="1">
                                <Input
                                  name={`links.${index}.title`}
                                  type="text"
                                  label={"Link Name " + (index+1) + "*"}
                                  id={`links.${index}.title`}
                                  icon={<MdOutlineTitle />}
                                  iconColor={errors.links ? "danger.100" : "primary.200"}
                                  touched={touched.links}
                                  errors={errors.links}                                                                
                                />
                              </Box>
                              <Box mr={{md: "2rem"}} flex="1">
                                <Input
                                  name={`links.${index}.href`}
                                  type="text"
                                  label={"Link URL " + (index+1) + "*"}
                                  id={`links.${index}.href`}
                                  icon={<BiLinkAlt />}
                                  iconColor={errors.links ? "danger.100" : "primary.200"}
                                  touched={touched.links}
                                  errors={errors.links}                                                                
                                />
                              </Box>                      
                              <Box mr={{md: "2rem"}} flex="0.5">
                                <Input
                                  name={`links.${index}.index`}
                                  type="number"
                                  label={"Link Index " + (index+1) + "*"}
                                  id={`links.${index}.index`}
                                  icon={<ImListNumbered />}
                                  iconColor={errors.links ? "danger.100" : "primary.200"}
                                  touched={touched.links}
                                  errors={errors.links}                                                                
                                />
                              </Box>
                              <Flex flex="0.5">
                                <Box mr="3rem" mt="1.5rem">
                                  <FormControlLabel 
                                    control={
                                      <Switch                                    
                                        checked={values.links[index].new}
                                        name={`links.${index}.new`}
                                        color="secondary"
                                        onChange={(e) => setFieldValue(`links.${index}.new`, e.target.checked)}
                                      />
                                    }
                                    label="New Link?"
                                  />
                                </Box>
                                <Box mr="3rem" mt="1.5rem">
                                  <FormControlLabel 
                                    control={
                                      <Switch                                    
                                        checked={values.links[index].ext}
                                        name={`links.${index}.ext`}
                                        color="primary"
                                        onChange={(e) => setFieldValue(`links.${index}.ext`, e.target.checked)}
                                      />
                                    }
                                    label="External Link?"
                                  />
                                </Box>
                              </Flex>
                              <Box
                                width="fit-content"
                                p="1rem 2rem"
                                borderRadius="0.5rem"
                                type="button"                          
                                onClick={() => arrayHelpers.remove(index)}
                                color="danger.100"
                                mt={{md: "1.5rem"}}
                                cursor="pointer"
                                mx={{xs: "auto", md: "0"}}
                              >
                                <MdOutlineDelete
                                  fontSize="2rem"
                                />
                              </Box>                      
                            </Flex>
                          ))}
                        </Flex>
                      )}
                    />   
                  </Box>                                 
                  <Flex justifyContent="space-between">
                    <Button
                      variant="warning"                    
                      px="2rem"
                      py="1rem"
                      type="button"                    
                      mt="1rem"                                                                                   
                      borderRadius="0.5rem"
                      width="fit-content"
                      onClick={() => {                        
                        setEditMode(false);
                        setImpLink(null);                        
                        resetForm()
                      }}
                    >
                      <Text fontSize="1.4rem" fontWeight="400">
                        Reset
                      </Text>
                    </Button>
                    <Button
                      variant="success"                    
                      px="2rem"
                      py="1rem"
                      type="submit"                    
                      mt="1rem"                                       
                      loading={loading}
                      textTransform="uppercase"
                      borderRadius="0.5rem"
                      width="fit-content"
                    >
                      <Text fontSize="1.6rem" fontWeight="500">
                        {editMode ? "Update" : "Add"} Section
                      </Text>
                    </Button>                    
                  </Flex>
                </Form>
              )
            }}
          </Formik>
        </Box>        
      </Box>

      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
        mt="4rem"
      >        
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="1.8rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
            color="primary.200"
          >
            Manage Important Links
          </Text>          
        </Flex> 

        <Grid
          mt="3rem"
          gridTemplateColumns={{md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)"}}
          gridColumnGap="4rem"
          gridRowGap="3rem"
        >          
          {importantLinks.map((section, index) => (
            <Box
              key={index}
              width="100%"
              py="2.5rem"
              px="1.5rem"      
              bg="white"
              borderRadius="0.4rem"
              boxShadow="1px 1px 2px -1px hsl(0deg 2% 51% / 75%)"
              border="1px solid hsl(0deg 0% 80% / 50%)"
            >
              <Flex
                flexDirection="column"        
              >
                <Flex alignItems="center" justifyContent="space-between" mb="2rem">
                  <Flex alignItems="center">
                    <Box
                      width="3rem"
                      height="3rem"
                      mr="1.5rem"
                    >
                      <Image
                        src={section.img}
                        alt={section.title}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                    <Text fontSize="1.6rem" fontWeight="600">{section.title}</Text>
                  </Flex>
                  <Flex ml="2rem">
                    <Box
                      fontSize="2rem"
                      color="warning.100"
                      mr="1.5rem"
                      cursor="pointer"
                      onClick={() => {
                        setImpLink(section);
                        setEditMode(true);                       
                        window.location.href = "#form";                     
                      }}
                    >
                      <FiEdit2/>
                    </Box>
                    <Box
                      fontSize="2rem"
                      color="danger.100"
                      cursor="pointer"
                      onClick={() => {
                        setForDelete(section);
                        setDeleteModalOpen(true);
                      }}
                    >
                      <MdDeleteOutline/>
                    </Box>
                  </Flex>
                </Flex>
                {
                  section.links?.sort((a, b) => (parseInt(a.index) < parseInt(b.index) ? 1 : -1)).map((link, index) => (
                    <Flex my="1rem" justifyContent="space-between" alignItems="start" key={index}>
                      {
                        link.ext ?
                          <a href={link.href} target="_blank" rel="noreferrer">
                            <Grid
                              gridTemplateColumns="2rem 1fr"
                              gridColumnGap="1rem"
                              key={index}
                            >
                              <Box color={link.new ? "danger.100" : "accent.100"}>
                                <BiLinkAlt size="1.8rem"/>
                              </Box>
                              <Text fontSize="1.4rem" mr="2.5rem" fontWeight={link.new ? "600" : "500"} color={link.new ? "danger.100" : "grey.400"}>
                                {link.title}
                              </Text>
                            </Grid>
                          </a>
                        : <a href={"https://ssju.ac.in"+ link.href} target="_blank" rel="noreferrer">                  
                          <Grid
                            gridTemplateColumns="2rem 1fr"
                            gridColumnGap="1rem"
                            key={index}
                          >                    
                            <Box color={link.new ? "danger.100" : "accent.100"}>
                              <BiLinkAlt size="1.8rem" />
                            </Box>
                            <Text fontSize="1.4rem" mr="2.5rem" fontWeight={link.new ? "600" : "500"} color={link.new ? "danger.100" : "grey.400"}>
                              {link.title}
                            </Text>
                          </Grid>                  
                        </a>
                      }   
                      {
                        link.new &&
                          <Box ml="2rem" width="2.5rem" height="2.5rem" position="absolute" top="0" right="0">
                            <Image
                              src="https://ssju.ac.in/assets/images/new.png"
                              alt="new-gif"
                              width="100%"
                              height="100%"
                            />
                          </Box>  
                      }                       
                    </Flex>
                  ))
                }
              </Flex>
            </Box>
          ))}
        </Grid>   
      </Box>
    </>
  )
}