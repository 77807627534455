import { Layout } from "organisms";
import { NewsPage } from "templates";

const News = () => {

  document.title = "News | ADMIN SSJU";

  return (
    <Layout>
      <NewsPage/>
    </Layout>
  )
}

export default News