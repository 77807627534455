import { Layout } from "organisms";
import { ExamSchedulePage } from "templates";

const ExamSchedule = () => {

  document.title = "Exam Schedule | ADMIN SSJU";

  return (
    <Layout>
      <ExamSchedulePage/>
    </Layout>
  )
}

export default ExamSchedule