import { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { AiOutlineBook, AiOutlineBorderlessTable, AiOutlineMail, AiOutlinePhone, AiOutlineUser } from "react-icons/ai";
import { BiLink, BiMessageSquareDetail } from "react-icons/bi";
import { ImListNumbered } from "react-icons/im";
import { MdOutlineDelete, MdOutlineFolderSpecial, MdOutlineTitle } from "react-icons/md";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FormControlLabel, Switch } from "@material-ui/core";

import { Box, Button, Flex, Image, Input, InputSelect, Loader, Text } from "atoms";
import { getFaculties } from "redux/actions";
import { db } from "utils/firebase";
import { getFirebaseTimestamp, uploadImageToFirebase, uploadPdfToFirebase } from "utils/utilities";

const syllabusValidationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"), 
});

const memberValidationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters"),
  designation: Yup.string()
    .required("Designation is required")
    .min(3, "Designation must be at least 3 characters"),
  email: Yup.string()    
    .email("Invalid email address"),
  phone: Yup.string()    
    .min(10, "Phone must be at least 10 characters"),  
});

const mDValidationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"), 
});

const courseValidationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters"), 
  index: Yup.number()
    .required("Index is required")  
});

const aboutValidationSchema = Yup.object({
  type: Yup.number()
    .required("About Type is required"),    
  index: Yup.number()
    .required("Index is required")  
});

const memberDesignation = [
  { value: "Professor & Head", label: "1.Professor & Head" },
  { value: "Professor", label: "2.Professor" },
  { value: "Assistant Professor", label: "3.Assistant Professor" },
  { value: "Assistant Professor(Contract)", label: "4.Assistant Professor(Contract)" },
  { value: "Assistant Professor(Guest)", label: "5.Assistant Professor(Guest)" },  
]

const aboutType = [
  { value: 0, label: "Paragrapgh/Text" },
  { value: 1, label: "List" },
  { value: 2, label: "Images" },  
  { value: 3, label: "Table" },
  { value: 4, label: "Heading" },
  { value: 5, label: "Link" },
]

export const FacultiesPage = () => {

  const [ loading, setLoading ] = useState(false);    
  const [ facultyId, setFacultyId ] = useState("");
  const [ departmentId, setDepartmentId ] = useState("");
  const [ faculty, setFaculty ] = useState(null);
  const [ showOptions, setShowOptions ] = useState(false);

  const [ syllabusDropdown, setSyllabusDropdown ] = useState(false);
  const [ memberDropdown, setMemberDropdown ] = useState(false);
  const [ mDDropdown, setMDDropdown ] = useState(false);
  const [ courseDropdown, setCourseDropdown ] = useState(false);
  const [ aboutDropdown, setAboutDropdown ] = useState(false);

  const dispatch = useDispatch();
  const { faculties, loading: reducerLoading } = useSelector(state => state.app);

  useEffect(() => {
    if(faculties.length === 0)  dispatch(getFaculties());
    if(facultyId !== ""){
      setFaculty(faculties.find(faculty => faculty.id === facultyId));
      if(!faculty?.haveDept){
        setShowOptions(true);
      }else setShowOptions(false);
    }   
    if(departmentId !== "") setShowOptions(true);
  }, [dispatch, facultyId, departmentId, faculty]);

  const onSyllabusSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);     
    let syllabusUrl = "";
    if(values.file){
      syllabusUrl = await uploadPdfToFirebase(values.file, "pdf/syllabus");
    }else{
      toast.error("Syllabus File Required!");
      return;
    }    
    let syllabus = {
      title: values.title,
      syllabusUrl,            
      timestamp: getFirebaseTimestamp(),
    }       
    if(faculty?.haveDept){
      await db.collection("faculty").doc(facultyId).collection("department").doc(departmentId).collection("syllabus").add(syllabus);     
    }else{
      await db.collection("faculty").doc(facultyId).collection("syllabus").add(syllabus);
    }    
    toast.success("Syllabus Added Successfully!");    
    resetForm();
    setSyllabusDropdown(false);
    setLoading(false);
    setSubmitting(false);          
  } 

  const onMemberSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true); 
    let imageURL = "", profileURL = ""
    if(values.image){
      imageURL = await uploadImageToFirebase(values.image, "images/faculty/members");
    }else{
      toast.error("Image Required!");
      return;
    }
    if(values.profile){
      profileURL = await uploadPdfToFirebase(values.profile, "pdf/faculty/members");
    }
    let member = {
      memberName: values.name,      
      priority: values.priority,
      designation: values.designation,
      specialization: values.specialization,
      email: values.email,
      phone: values.phone,
      imageURL,
      profileURL,
    }   
    if(values.isHOD)  member.isHOD = true;              
    if(faculty?.haveDept){
      await db.collection("faculty").doc(facultyId).collection("department").doc(departmentId).collection("members").add(member);     
    }else{
      await db.collection("faculty").doc(facultyId).collection("members").add(member);
    }    
    toast.success("Member Added Successfully!");    
    resetForm();
    setMemberDropdown(false);
    setLoading(false);
    setSubmitting(false);          
  } 

  const onMDSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);     
    let fileUrl = "";
    if(values.file){
      fileUrl = await uploadPdfToFirebase(values.file, "pdf/marksD");
    }else{
      toast.error("Distribution File Required!");
      return;
    }    
    let mD = {
      title: values.title,
      marksDUrl: fileUrl,            
      timestamp: getFirebaseTimestamp(),
    }       
    if(faculty?.haveDept){
      await db.collection("faculty").doc(facultyId).collection("department").doc(departmentId).collection("marksDistribution").add(mD);     
    }else{
      await db.collection("faculty").doc(facultyId).collection("marksDistribution").add(mD);
    }    
    toast.success("Marks Distribution Added Successfully!");    
    resetForm();
    setMDDropdown(false);
    setLoading(false);
    setSubmitting(false);          
  } 

  const onCourseSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);         
    let course = {
      courseName: values.name,
      courseDesc: values.desc,
      courseTable: values.table,
      warning: values.points,
      index: values.index,
    }       
    if(faculty?.haveDept){
      await db.collection("faculty").doc(facultyId).collection("department").doc(departmentId).collection("courses").add(course);     
    }else{
      await db.collection("faculty").doc(facultyId).collection("courses").add(course);
    }    
    toast.success("Course Added Successfully!");    
    resetForm();
    setCourseDropdown(false);
    setLoading(false);
    setSubmitting(false);          
  } 

  const onAboutSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true); 
    let imagesArr = [];     
    if(values.type == 2){
      for(let i = 0; i < values.images.length; i++){
        let image = await uploadImageToFirebase(values.images[i], "images/faculty/about");
        imagesArr.push(image);
      }
    }
    let about = {
      viewType: parseInt(values.type),
      position: parseInt(values.index),
    }   
    if(values.type == 0){
      about.pTitle = values.pTitle;
      about.pBody = values.paragraph;
    }else if(values.type == 1){
      about.lBody = values.list;
      about.lTitle = values.listTitle;
    }else if(values.type == 2){
      about.url = imagesArr;
    }else if(values.type == 4){
      about.heading = values.heading;
    }else if(values.type == 5){
      about.title = values.srcTitle;
      about.url = values.url;
    }          
    if(faculty?.haveDept){
      await db.collection("faculty").doc(facultyId).collection("department").doc(departmentId).collection("about").add(about);     
    }else{
      await db.collection("faculty").doc(facultyId).collection("about").add(about);
    }    
    toast.success("About Added Successfully!");    
    resetForm();
    setAboutDropdown(false);
    setLoading(false);
    setSubmitting(false);          
  } 

  return (
    <>
      {(loading || reducerLoading) && <Loader />}
      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
      >        
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="1.8rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
            color="primary.200"
          >
            Manage Faculties
          </Text>
          <Button
            variant="warning"
            p="1rem 2rem"
            width="fit-content"
            borderRadius="0.5rem"
          >
            Add Faculty
          </Button>
        </Flex> 

        <Flex my="2rem" flexDirection={{xs: "column", md: "row"}}>
          <Box mr={{ md: "1rem"}} flex="1" maxWidth="60rem">
            <InputSelect
              value={facultyId}
              setValue={(e) => {
                setDepartmentId("");
                setShowOptions(false);
                setFacultyId(e.target.value);              
              }}
              options={
                faculties.map(faculty => ({
                  value: faculty.id,
                  label: faculty.name
                }))
              }
              label={"Faculty"}
              placeholder={"Select Faculty"}
            />
          </Box>
          {faculty?.haveDept && (
            <Box mr={{ md: "1rem"}} flex="1" mt={{xs: "1rem", md: "0"}}>
              <InputSelect
                value={departmentId}
                setValue={(e) => setDepartmentId(e.target.value)}
                options={
                  faculty?.deptName.map(dept => ({
                    value: dept,
                    label: dept
                  }))
                }
                label={"Department"}
                placeholder={"Select Department"}
              />
            </Box>
          )}
        </Flex>   
      </Box>

      {showOptions && (
        <>
          <Box
            width="100%"
            bg="white"
            p="2rem"
            borderRadius="0.5rem"
            my="2rem"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                fontSize="1.8rem"
                fontWeight="600"
                mb="1rem"
                textTransform="uppercase"
                color="primary.200"
              >
                Add Syllabus
              </Text>
              <Box
                fontSize="1.8rem"
                fontWeight="600"
                cursor="pointer"
                onClick={() => setSyllabusDropdown(!syllabusDropdown)}
                className={syllabusDropdown ? "rotate-180" : ""}
              >
                <HiOutlineChevronDown/>
              </Box>
            </Flex>
            {syllabusDropdown && (
              <Box my="2rem">
                <Formik
                  initialValues={{              
                    title: "",
                    file: null,              
                  }}
                  validationSchema={syllabusValidationSchema}
                  onSubmit={onSyllabusSubmit}
                  enableReinitialize
                >
                  {({ touched, errors, setFieldValue }) => {
                    return (                  
                      <Form>  
                        <Flex
                          flexDirection={{xs: "column", md: "row"}}
                        >
                          <Box mr={{xs: "0", md: "2rem"}} flex="1">
                            <Input
                              name="title"
                              type="text"
                              label="Syllabus Title*"                        
                              id="title"
                              icon={<MdOutlineTitle />}
                              iconColor={errors.title ? "danger.100" : "primary.200"}
                              touched={touched.title}
                              errors={errors.title}                            
                            />   
                          </Box>
                          <Box mt={{xs: "2rem", md: 0}} flex="1">
                            <Text                              
                              fontSize="1.4rem"
                              fontWeight="400"
                              color="grey.300"          
                            >
                              Syllabus File*
                            </Text>
                            <br/>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              accept="application/pdf"
                              onChange={(e) => {
                                if(e.target.files[0]){
                                  setFieldValue("file", e.currentTarget.files[0]);
                                }
                              }}
                            />
                          </Box>  
                        </Flex>                                                                                                                                              

                        <Button
                          variant="success"                    
                          px="2rem"
                          py="1rem"
                          type="submit"                    
                          mt="1rem"                                       
                          loading={loading}
                          textTransform="uppercase"
                          borderRadius="0.5rem"
                          width="fit-content"
                        >
                          <Text fontSize="1.6rem" fontWeight="500">
                            Add Syllabus
                          </Text>
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </Box>
            )}
          </Box>

          <Box
            width="100%"
            bg="white"
            p="2rem"
            borderRadius="0.5rem"
            my="2rem"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                fontSize="1.8rem"
                fontWeight="600"
                mb="1rem"
                textTransform="uppercase"
                color="primary.200"
              >
                Add Member
              </Text>
              <Box
                fontSize="1.8rem"
                fontWeight="600"
                cursor="pointer"
                onClick={() => setMemberDropdown(!memberDropdown)}
                className={memberDropdown ? "rotate-180" : ""}
              >
                <HiOutlineChevronDown/>
              </Box>
            </Flex>
            {memberDropdown && (
              <Box my="2rem">
                <Formik
                  initialValues={{              
                    name: "",
                    designation: "",   
                    specialization: "",
                    email: "",
                    phone: "",
                    image: null,
                    profile: null,
                    priority: null,
                    isHOD: false
                  }}
                  validationSchema={memberValidationSchema}
                  onSubmit={onMemberSubmit}
                  enableReinitialize
                >
                  {({ touched, errors, setFieldValue, values }) => {
                    return (                  
                      <Form>  
                        <Flex
                          flexDirection={{xs: "column", md: "row"}}
                        >
                          <Box mr={{xs: "0", md: "2rem"}} flex="1">
                            <Input
                              name="name"
                              type="text"
                              label="Member Name*"                        
                              id="name"
                              icon={<AiOutlineUser />}
                              iconColor={errors.name ? "danger.100" : "primary.200"}
                              touched={touched.name}
                              errors={errors.name}  
                              lessMB                          
                            />   
                          </Box>
                          <Box mr={{xs: "0", md: "2rem"}} mt={{xs: "1rem", md: 0}} flex="1">
                            <InputSelect
                              value={values.designation}
                              setValue={(e) => setFieldValue("designation", e.target.value)}
                              options={memberDesignation}
                              label={"Member Designation*"}
                              placeholder={"Select Designation"}                              
                            />                              
                          </Box>
                          <Box mt={{xs: "1rem", md: 0}} flex="0.6">
                            <Input
                              name="priority"
                              type="number"
                              label="Member Priority*"                        
                              id="priority"
                              icon={<ImListNumbered />}
                              iconColor={errors.priority ? "danger.100" : "primary.200"}
                              touched={touched.priority}
                              errors={errors.priority}   
                              lessMB                         
                            /> 
                          </Box>  
                        </Flex>                                                                                                                                              
                        <Flex
                          flexDirection={{xs: "column", md: "row"}}
                          mt={{md: "2rem"}}
                        >
                          <Box mr={{xs: "0", md: "2rem"}} mt={{xs: "1rem", md: 0}} flex="1">
                            <Input
                              name="email"
                              type="email"
                              label="Member Email*"                        
                              id="email"
                              icon={<AiOutlineMail />}
                              iconColor={errors.email ? "danger.100" : "primary.200"}
                              touched={touched.email}
                              errors={errors.email} 
                              lessMB                           
                            />   
                          </Box>
                          <Box mr={{xs: "0", md: "2rem"}} mt={{xs: "1rem", md: 0}} flex="1">
                            <Input
                              name="phone"
                              type="text"
                              label="Member Phone*"                        
                              id="phone"
                              icon={<AiOutlinePhone />}
                              iconColor={errors.phone ? "danger.100" : "primary.200"}
                              touched={touched.phone}
                              errors={errors.phone}   
                              lessMB                         
                            />                              
                          </Box>
                          <Box mt={{xs: "1rem", md: 0}} flex="1">
                            <Input
                              name="specialization"
                              type="text"
                              label="Member Specialization*"                        
                              id="specialization"
                              icon={<MdOutlineFolderSpecial />}
                              iconColor={errors.specialization ? "danger.100" : "primary.200"}
                              touched={touched.specialization}
                              errors={errors.specialization}    
                              lessMB                        
                            /> 
                          </Box>  
                        </Flex>  
                        <Flex
                          flexDirection={{xs: "column", md: "row"}}
                          mt={{md: "2rem"}}
                          mb="2rem"
                          // justifyContent="space-between"
                        >
                          <Box>
                            {values.image &&                         
                              <Image 
                                src={URL.createObjectURL(values.image)} 
                                width="fit-content" 
                                height="10rem" 
                                objectFit="contain"  
                                borderRadius="0.5rem"                           
                                style={{border: "2px solid whitesmoke", marginRight: "2rem", marginBottom: "1rem"}}
                              />}
                            <input
                              type="file"
                              accept="image/*"
                              id="image"
                              name="image"
                              style={{ display: "none" }}
                              onChange={(e)=> {
                                if(e.target.files[0]) {
                                  setFieldValue("image", e.target.files[0])
                                }
                              }}
                            />
                            <Box>
                              <Button
                                as="label"
                                htmlFor="image"
                                variant="info"
                                width="fit-content"                            
                                p="1rem 2rem"
                                borderRadius="0.5rem"                       
                              >
                                Pick Member Image
                              </Button>
                            </Box>
                          </Box>
                          <Box ml={{md: "4rem"}} mt={{xs: "2rem", md: "0"}}>
                            <Text                              
                                fontSize="1.4rem"
                                fontWeight="400"
                                color="grey.300"          
                              >
                                Member Profile*
                              </Text>
                              <br/>
                              <input
                                type="file"
                                name="profile"
                                id="profile"
                                accept="application/pdf"
                                onChange={(e) => {
                                  if(e.target.files[0]){
                                    setFieldValue("profile", e.currentTarget.files[0]);
                                  }
                                }}
                              />
                            </Box>
                            <Box>
                            <FormControlLabel 
                              control={
                                <Switch                                    
                                  checked={values.isHOD}
                                  name="isHOD"
                                  color="secondary"
                                  onChange={(e) => setFieldValue("isHOD", e.target.checked)}                            
                                />
                              }
                              label='Member is HOD?'
                            />
                            </Box>
                        </Flex>  
                        <Button
                          variant="success"                    
                          px="2rem"
                          py="1rem"
                          type="submit"                    
                          mt="1rem"                                       
                          loading={loading}
                          textTransform="uppercase"
                          borderRadius="0.5rem"
                          width="fit-content"
                        >
                          <Text fontSize="1.6rem" fontWeight="500">
                            Add Member
                          </Text>
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </Box>
            )}
          </Box>

          <Box
            width="100%"
            bg="white"
            p="2rem"
            borderRadius="0.5rem"
            my="2rem"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                fontSize="1.8rem"
                fontWeight="600"
                mb="1rem"
                textTransform="uppercase"
                color="primary.200"
              >
                Add Marks Distribution
              </Text>
              <Box
                fontSize="1.8rem"
                fontWeight="600"
                cursor="pointer"
                onClick={() => setMDDropdown(!mDDropdown)}
                className={mDDropdown ? "rotate-180" : ""}
              >
                <HiOutlineChevronDown/>
              </Box>
            </Flex>
            {mDDropdown && (
              <Box my="2rem">
                <Formik
                  initialValues={{              
                    title: "",
                    file: null,              
                  }}
                  validationSchema={mDValidationSchema}
                  onSubmit={onMDSubmit}
                  enableReinitialize
                >
                  {({ touched, errors, setFieldValue }) => {
                    return (                  
                      <Form>  
                        <Flex
                          flexDirection={{xs: "column", md: "row"}}
                        >
                          <Box mr={{xs: "0", md: "2rem"}} flex="1">
                            <Input
                              name="title"
                              type="text"
                              label="Marks Distribution Title*"                        
                              id="title"
                              icon={<MdOutlineTitle />}
                              iconColor={errors.title ? "danger.100" : "primary.200"}
                              touched={touched.title}
                              errors={errors.title}                            
                            />   
                          </Box>
                          <Box mt={{xs: "2rem", md: 0}} flex="1">
                            <Text                              
                              fontSize="1.4rem"
                              fontWeight="400"
                              color="grey.300"          
                            >
                              Marks Distribution File*
                            </Text>
                            <br/>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              accept="application/pdf"
                              onChange={(e) => {
                                if(e.target.files[0]){
                                  setFieldValue("file", e.currentTarget.files[0]);
                                }
                              }}
                            />
                          </Box>  
                        </Flex>                                                                                                                                              

                        <Button
                          variant="success"                    
                          px="2rem"
                          py="1rem"
                          type="submit"                    
                          mt="1rem"                                       
                          loading={loading}
                          textTransform="uppercase"
                          borderRadius="0.5rem"
                          width="fit-content"
                        >
                          <Text fontSize="1.6rem" fontWeight="500">
                            Add Marks Distribution
                          </Text>
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </Box>
            )}
          </Box>

          <Box
            width="100%"
            bg="white"
            p="2rem"
            borderRadius="0.5rem"
            my="2rem"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                fontSize="1.8rem"
                fontWeight="600"
                mb="1rem"
                textTransform="uppercase"
                color="primary.200"
              >
                Add Course
              </Text>
              <Box
                fontSize="1.8rem"
                fontWeight="600"
                cursor="pointer"
                onClick={() => setCourseDropdown(!courseDropdown)}
                className={courseDropdown ? "rotate-180" : ""}
              >
                <HiOutlineChevronDown/>
              </Box>
            </Flex>
            {courseDropdown && (
              <Box my="2rem">
                <Formik
                  initialValues={{              
                    name: "",
                    desc: "",   
                    index: null,
                    points: [],
                    table: []                    
                  }}
                  validationSchema={courseValidationSchema}
                  onSubmit={onCourseSubmit}
                  enableReinitialize
                >
                  {({ touched, errors, setFieldValue, values }) => {
                    return (                  
                      <Form>  
                        <Flex
                          flexDirection={{xs: "column", md: "row"}}
                        >
                          <Box mr={{xs: "0", md: "2rem"}} flex="1">
                            <Input
                              name="name"
                              type="text"
                              label="Course Name*"                        
                              id="name"
                              icon={<AiOutlineBook />}
                              iconColor={errors.name ? "danger.100" : "primary.200"}
                              touched={touched.name}
                              errors={errors.name}  
                              lessMB                          
                            />   
                          </Box>                          
                          <Box mt={{xs: "1rem", md: 0}} flex="0.6">
                            <Input
                              name="index"
                              type="number"
                              label="Course Index*"                        
                              id="index"
                              icon={<ImListNumbered />}
                              iconColor={errors.index ? "danger.100" : "primary.200"}
                              touched={touched.index}
                              errors={errors.index}   
                              lessMB                         
                            /> 
                          </Box>  
                        </Flex>                                                                                                                                              
                        <Flex
                          flexDirection={{xs: "column", md: "row"}}
                          mt={{md: "2rem"}}
                        >                          
                          <Box mr={{xs: "0", md: "2rem"}} mt={{xs: "1rem", md: 0}} flex="1">
                            <Input
                              name="desc"
                              type="text"
                              label="Course Description"                        
                              id="desc"
                              icon={<BiMessageSquareDetail />}
                              iconColor={errors.desc ? "danger.100" : "primary.200"}
                              touched={touched.desc}
                              errors={errors.desc} 
                              textArea
                              rows={4}
                              lessMB                         
                            />                              
                          </Box>
                          <Box mt={{xs: "1rem", md: 0}} flex="1">                            
                            <Button
                              type="button"
                              variant="info"
                              p="1rem 2rem"
                              width="fit-content"
                              mt="1.8rem"
                              borderRadius="0.5rem"
                              onClick={() => {
                                setFieldValue("table", [...values.table, ""]);
                              }}
                            >
                              Add Table Row
                            </Button>
                            <Button
                              type="button"
                              variant="warning"
                              p="1rem 2rem"
                              width="fit-content"
                              mt="1rem"
                              borderRadius="0.5rem"
                              onClick={() => {
                                setFieldValue("points", [...values.points, ""]);
                              }}
                            >
                              Add Warning Point
                            </Button>
                          </Box>  
                        </Flex>  
                        <FieldArray
                          name="table"
                          render={(arrayHelpers) => (
                            <Flex
                              my="2rem"
                              flexWrap="wrap"
                            >
                              {values.table.map((src, index) => (                                
                                <Flex 
                                  key={index}
                                  minWidth={{xs: "100%", md: "50rem"}}                                                              
                                  mr={{md: "1rem"}}
                                >
                                  <Box flex="1">
                                    <Input
                                      name={`table.${index}`}
                                      type="text"
                                      label={"Table Row(Title = Description) " + (index+1) + "*"}
                                      id={`table.${index}`}
                                      icon={<AiOutlineBorderlessTable />}
                                      iconColor={errors.table ? "danger.100" : "primary.200"}
                                      touched={touched.table}
                                      errors={errors.table}                                                                
                                    />
                                  </Box>                                                       
                                  <Box
                                    width="fit-content"
                                    p="1rem 2rem"
                                    borderRadius="0.5rem"
                                    type="button"                          
                                    onClick={() => arrayHelpers.remove(index)}
                                    color="danger.100"
                                    mt={{md: "1.5rem"}}
                                    cursor="pointer"
                                    mx={{xs: "auto", md: "0"}}
                                  >
                                    <MdOutlineDelete
                                      fontSize="2rem"
                                    />
                                  </Box>                      
                                </Flex>
                              ))}
                            </Flex>
                          )}
                        />
                        <FieldArray
                          name="points"
                          render={(arrayHelpers) => (
                            <Flex
                              my="2rem"
                              flexWrap="wrap"
                            >
                              {values.points.map((point, index) => (                                
                                <Flex 
                                  key={index}
                                  minWidth={{xs: "100%", md: "50rem"}}                                 
                                  mr={{md: "1rem"}}
                                >
                                  <Box flex="1">
                                    <Input
                                      name={`points.${index}`}
                                      type="text"
                                      label={"Warning " + (index+1) + "*"}
                                      id={`points.${index}`}
                                      icon={<ImListNumbered />}
                                      iconColor={errors.points ? "danger.100" : "primary.200"}
                                      touched={touched.points}
                                      errors={errors.points}                                                                
                                    />
                                  </Box>                                                       
                                  <Box
                                    width="fit-content"
                                    p="1rem 2rem"
                                    borderRadius="0.5rem"
                                    type="button"                          
                                    onClick={() => arrayHelpers.remove(index)}
                                    color="danger.100"
                                    mt={{md: "1.5rem"}}
                                    cursor="pointer"
                                    mx={{xs: "auto", md: "0"}}
                                  >
                                    <MdOutlineDelete
                                      fontSize="2rem"
                                    />
                                  </Box>                      
                                </Flex>
                              ))}
                            </Flex>
                          )}
                        />                        
                        <Button
                          variant="success"                    
                          px="2rem"
                          py="1rem"
                          type="submit"                    
                          mt="1rem"                                       
                          loading={loading}
                          textTransform="uppercase"
                          borderRadius="0.5rem"
                          width="fit-content"
                        >
                          <Text fontSize="1.6rem" fontWeight="500">
                            Add Course
                          </Text>
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </Box>
            )}
          </Box>

          <Box
            width="100%"
            bg="white"
            p="2rem"
            borderRadius="0.5rem"
            my="2rem"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                fontSize="1.8rem"
                fontWeight="600"
                mb="1rem"
                textTransform="uppercase"
                color="primary.200"
              >
                Add About
              </Text>
              <Box
                fontSize="1.8rem"
                fontWeight="600"
                cursor="pointer"
                onClick={() => setAboutDropdown(!aboutDropdown)}
                className={aboutDropdown ? "rotate-180" : ""}
              >
                <HiOutlineChevronDown/>
              </Box>
            </Flex>
            {aboutDropdown && (
              <Box my="2rem">
                <Formik
                  initialValues={{              
                    type: null,
                    index: null,
                    images: [],
                    imagesPreview: [],
                    heading: "",
                    pTitle: "",                                           
                    paragraph: "",
                    listTitle: "",
                    list: [],  
                    srcTitle: "",
                    url: ""                  
                  }}
                  validationSchema={aboutValidationSchema}
                  onSubmit={onAboutSubmit}
                  enableReinitialize
                >
                  {({ touched, errors, setFieldValue, values }) => {
                    return (                  
                      <Form>  
                        <Flex
                          flexDirection={{xs: "column", md: "row"}}
                        >
                          <Box mr={{xs: "0", md: "2rem"}} flex="1">
                            <InputSelect
                              value={values.type}
                              setValue={(e) => setFieldValue("type", e.target.value)}
                              options={aboutType}
                              label={"About Type*"}
                              placeholder={"Select About Type"}
                              name="type"                              
                              id="type"                              
                            />                           
                          </Box>                          
                          <Box mt={{xs: "1rem", md: 0}} flex="0.6">
                            <Input
                              name="index"
                              type="number"
                              label="Index*"                        
                              id="index"
                              icon={<ImListNumbered />}
                              iconColor={errors.index ? "danger.100" : "primary.200"}
                              touched={touched.index}
                              errors={errors.index}   
                              lessMB                         
                            /> 
                          </Box>  
                        </Flex> 
                        {values.type == 0 && (
                          <Box my="1rem">
                            <Input
                              name="pTitle"
                              type="text"
                              label="Paragraph Title*"
                              id="pTitle"
                              icon={<MdOutlineTitle />}
                              iconColor={errors.pTitle ? "danger.100" : "primary.200"}
                              touched={touched.pTitle}
                              errors={errors.pTitle}
                              lessMB
                            />
                            <Input
                              name="paragraph"
                              type="text"
                              label="Paragraph*"
                              id="paragraph"
                              icon={<BiMessageSquareDetail />}
                              iconColor={errors.paragraph ? "danger.100" : "primary.200"}
                              touched={touched.paragraph}
                              errors={errors.paragraph}
                              textArea
                              lessMB
                              rows={5}
                            />
                          </Box>
                        )}      
                        {values.type == 1 && (
                          <Box my="1rem">
                            <Flex>
                              <Box flex="1">
                                <Input
                                  name="listTitle"
                                  type="text"
                                  label="List Title*"
                                  id="listTitle"
                                  icon={<MdOutlineTitle />}
                                  iconColor={errors.listTitle ? "danger.100" : "primary.200"}
                                  touched={touched.listTitle}
                                  errors={errors.listTitle}
                                  lessMB
                                />                                
                              </Box>
                              <Box>
                                <Button
                                  width="fit-content"
                                  ml="2rem"
                                  variant="warning"
                                  p="1rem 2rem"
                                  borderRadius="0.5rem"
                                  type="button"
                                  mt="1.8rem"                                  
                                  onClick={() => setFieldValue("list", [...values.list, ""])}
                                >                                  
                                  Add List Item                                 
                                </Button>
                              </Box>
                            </Flex>
                            <FieldArray
                              name="list"
                              render={(arrayHelpers) => (
                                <Flex
                                  my="2rem"
                                  flexWrap="wrap"
                                >
                                  {values.list.map((l, index) => (                                
                                    <Flex 
                                      key={index}
                                      minWidth={{xs: "100%", md: "50rem"}}                                                              
                                      mr={{md: "2rem"}}
                                    >
                                      <Box flex="1">
                                        <Input
                                          name={`list.${index}`}
                                          type="text"
                                          label={"List Item " + (index+1) + "*"}
                                          id={`list.${index}`}
                                          icon={<AiOutlineBorderlessTable />}
                                          iconColor={errors.list ? "danger.100" : "primary.200"}
                                          touched={touched.list}
                                          errors={errors.list}                                                                
                                        />
                                      </Box>                                                       
                                      <Box
                                        width="fit-content"
                                        p="1rem 2rem"
                                        borderRadius="0.5rem"
                                        type="button"                          
                                        onClick={() => arrayHelpers.remove(index)}
                                        color="danger.100"
                                        mt={{md: "1.5rem"}}
                                        cursor="pointer"
                                        mx={{xs: "auto", md: "0"}}
                                      >
                                        <MdOutlineDelete
                                          fontSize="2rem"
                                        />
                                      </Box>                      
                                    </Flex>
                                  ))}
                                </Flex>
                              )}
                            />  
                          </Box>
                        )} 
                        {values.type == 2 && (
                          <Flex my="2rem">
                            <Box>
                              <Button
                                as="label"
                                htmlFor="images"                        
                                variant="info"
                                width="fit-content"
                                mr="2rem"                              
                                p="1rem 2rem"                     
                                borderRadius="0.5rem"
                              >
                                Pick Images
                              </Button>
                            </Box>
                            <Flex
                              flexWrap="wrap"
                            >
                              {values.imagesPreview && values.imagesPreview?.map((image, index) => (
                                  <Box
                                    key={index}
                                    width="20rem"
                                    height="15rem"
                                    mr="2rem"
                                    mb="2rem"
                                    border="1px solid whitesmoke"
                                  >
                                    <Image
                                      src={image}                                
                                      width="100%"
                                      height="100%"
                                      objectFit="contain"
                                      borderRadius="0.5rem"
                                      alt={"image"+String(index)}                                
                                    />
                                  </Box>
                              ))}
                            </Flex>
                            <input                         
                              type="file"
                              accept="image/*"
                              id="images"
                              name="images"
                              style={{ display: "none" }}
                              multiple
                              onChange={(e)=>{
                                if(e.target.files.length > 0){
                                  let imagesArr = [...values.images];
                                  let imagePreviewArr = [...values.imagesPreview];
                                  for(let i = 0; i < e.target.files.length; i++){
                                    imagesArr.push(e.target.files[i]);
                                    imagePreviewArr.push(URL.createObjectURL(e.target.files[i]));
                                  }
                                  setFieldValue("images", imagesArr);
                                  setFieldValue("imagesPreview", imagePreviewArr);                                  
                                }                                
                              }}
                            />                            
                          </Flex>                          
                        )}   
                        {values.type == 4 && (
                          <Box my="1rem">
                            <Input
                              name="heading"
                              type="text"
                              label="Heading*"
                              id="heading"
                              icon={<MdOutlineTitle />}
                              iconColor={errors.heading ? "danger.100" : "primary.200"}
                              touched={touched.heading}
                              errors={errors.heading}
                              lessMB
                            />
                          </Box>
                        )}   
                        {values.type == 5 && (
                          <Flex my="1rem" flexDirection={{xs: "column", md: "row"}}>
                            <Box flex="1">
                              <Input
                                name="srcTitle"
                                type="text"
                                label="Source Title*"
                                id="srcTitle"
                                icon={<MdOutlineTitle />}
                                iconColor={errors.srcTitle ? "danger.100" : "primary.200"}
                                touched={touched.srcTitle}
                                errors={errors.srcTitle}
                                lessMB
                              />
                            </Box>
                            <Box flex="1" ml={{md: "2rem"}} mt={{xs: "1rem", md: "0"}}>
                              <Input
                                name="url"
                                type="text"
                                label="Source URL*"
                                id="url"
                                icon={<BiLink />}
                                iconColor={errors.url ? "danger.100" : "primary.200"}
                                touched={touched.url}
                                errors={errors.url}
                                lessMB
                              />
                            </Box>
                          </Flex>
                        )}                                                                                                                                                                                
                        <Button
                          variant="success"                    
                          px="2rem"
                          py="1rem"
                          type="submit"                    
                          mt="1rem"                                       
                          loading={loading}
                          textTransform="uppercase"
                          borderRadius="0.5rem"
                          width="fit-content"
                        >
                          <Text fontSize="1.6rem" fontWeight="500">
                            Add About
                          </Text>
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  )
}