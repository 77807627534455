import Box from "../Box";
import Text from "../Text";

export const InputSelect = ({
  value,
  setValue,
  options,
  label,
  placeholder
}) => {
  return (
    <Box      
      width="100%"
    >                 
      <Text
        fontSize="1.4rem"
        fontWeight="400"
        color="grey.300"
        mb="0.4rem"
      >
        {label}
      </Text>           
      <select
        className="form-input-select"
        style={{
          width: "100%",
          textTransform: "capitalize"
        }}
        value={value}
        onChange={(e) => setValue(e)}
      >
        <option value="">{placeholder}</option>
        {options?.map((item, index) => (
          <option  
            key={index} 
            value={item.value}
            style={{
              textTransform: "capitalize"
            }}
          >
            {item.label}
          </option>
        ))}
      </select>              
    </Box>  
  )
}