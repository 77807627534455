import { Layout } from "organisms";
import { DashboardPage } from "templates";

export const Dashboard = () => {

  document.title = "Dashboard | ADMIN SSJU";

  return (
    <Layout>
      <DashboardPage/>
    </Layout>
  );
};

export default Dashboard;
