import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { BiDetail } from "react-icons/bi";
import { IoMdLink } from "react-icons/io";
import { MdOutlineTitle } from "react-icons/md";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, Loader, Text } from "atoms";
import { db } from "utils/firebase";
import { getFirebaseTimestamp, uploadImageToFirebase } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"),
  desc: Yup.string()
    .required("Description is required")
    .min(3, "Description must be at least 3 characters"),
});

export const SportsPage = () => {

  const [ loading, setLoading ] = useState(false);
  const [ image, setImage ] = useState(null);  
  const [ error, setError ] = useState(null); 
  
  useEffect(() => {
    if(error){
      toast.error(error);
      setError(null);
    }       
  }, [loading, error]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true); 
    let imageUrl = "";
    if(image){
      imageUrl = await uploadImageToFirebase(image, "images/sports");
    }else{
      toast.error("Image Required");
      setLoading(false);
      return;
    }
    let activity = {
      title: values.title,
      desc: values.desc,
      imageUrl,      
      timestamp: getFirebaseTimestamp(),
    }
    values.srcTitle !== "" && (activity.srcTitle = values.srcTitle);
    values.galleryLink !== "" && (activity.galleryLink = values.galleryLink);
    console.log(activity);
    db.collection("sports").add(activity);
    toast.success("Sports Activity Added Successfully!");
    setImage(null);
    resetForm();
    setLoading(false);
    setSubmitting(false);          
  }

  return (
    <Box
      width="100%"
      bg="white"
      p="2rem"
      borderRadius="0.5rem"
    >
      {loading && <Loader />}
      <Text
        fontSize="1.8rem"
        fontWeight="600"
        mb="1rem"
        textTransform="uppercase"
        color="primary.200"
      >
        Add Sports Activity
      </Text>
      <Box my="2rem">
        <Formik
          initialValues={{              
            title: "",
            desc: "",
            srcTitle: "",
            galleryLink: "",            
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue }) => {
            return (                  
              <Form>  
                <Flex
                  flexDirection={{ xs: "column", md: "row" }}
                >
                  <Box flex="2">
                    <Input
                      name="title"
                      type="text"
                      label="Activity Title*"                        
                      id="title"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.title ? "danger.100" : "primary.200"}
                      touched={touched.title}
                      errors={errors.title}                            
                    />                  
                    <Input
                      name="desc"
                      type="text"                    
                      label="Activity Description*"
                      id="desc"
                      icon={<BiDetail />}
                      iconColor={errors.desc ? "danger.100" : "primary.200"}
                      touched={touched.desc}
                      errors={errors.desc}
                      textArea
                      rows={4}
                    />
                  </Box>
                  <Box flex="1" ml={{md: "1rem"}} mt="1.6rem">
                    {image &&                         
                      <Image 
                        src={URL.createObjectURL(image)} 
                        width="fit-content" 
                        height="18rem" 
                        objectFit="contain"  
                        borderRadius="0.5rem"                           
                        style={{border: "2px solid whitesmoke", margin: "0 auto", marginBottom: "1rem"}}
                      />}
                    <input
                      type="file"
                      accept="image/*"
                      id="image"
                      name="image"
                      style={{ display: "none" }}
                      onChange={(e)=> setImage(e.target.files[0])}
                    />
                    <Button
                      as="label"
                      htmlFor="image"
                      variant="info"
                      width="fit-content"
                      mx="auto"
                      p="1rem 2rem"
                      borderRadius="0.5rem"                       
                    >
                      Pick Activity Image
                    </Button>
                  </Box>
                </Flex> 
                <Flex
                  flexDirection={{ xs: "column", md: "row" }}
                  mb="2rem"
                >
                  <Box mr={{md: "2rem"}}>
                    <Input
                      name="srcTitle"
                      type="text"
                      label={"Source Title"}
                      id="srcTitle"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.srcTitle ? "danger.100" : "primary.200"}
                      touched={touched.srcTitle}
                      errors={errors.srcTitle}                                                                
                    />
                  </Box>
                  <Box mr={{md: "2rem"}}>
                    <Input
                      name="galleryLink"
                      type="text"
                      label={"Source URL"}
                      id="galleryLink"
                      icon={<IoMdLink />}
                      iconColor={errors.galleryLink ? "danger.100" : "primary.200"}
                      touched={touched.galleryLink}
                      errors={errors.galleryLink}                                                                
                    />
                  </Box> 
                </Flex>                                  

                <Button
                  variant="primary"                    
                  px="2rem"
                  py="1rem"
                  type="submit"                    
                  mt="1rem"                                       
                  loading={loading}
                  textTransform="uppercase"
                  borderRadius="0.5rem"
                  width="fit-content"
                >
                  <Text fontSize="1.6rem" fontWeight="500">
                    Add Sports Activity
                  </Text>
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}