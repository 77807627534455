import { Layout } from "organisms";
import { NEPSyllabusPage } from "templates";

const NEPSyllabus = () => {

  document.title = "NEP Syllabus | ADMIN SSJU";

  return (
    <Layout>
      <NEPSyllabusPage/>
    </Layout>
  )
}

export default NEPSyllabus