import firebase, { storage } from "utils/firebase";

export const isMobile = () => (typeof window !== "undefined" ? window.innerWidth <= 600 : false);
export const isTab = () => (typeof window !== "undefined" ? window.innerWidth <= 1024 : false);

export const scrollToTop = (window) =>{
  window.scrollTo({top: 0, left: 0, behavior: "smooth" })
}    

export const formatDate = (date) => {
  let formattedDate;
  if(typeof date === "object"){
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thusday", "Friday", "Saturday"]
    formattedDate = `${dayNames[date?.toDate().getDay()]}, ${monthNames[date?.toDate().getMonth()]} ${date?.toDate().getDate()}, ${date?.toDate().getFullYear()}`
  }else if(typeof date === "string"){
    formattedDate = date
  }
  return formattedDate
}

export const firbaseTimestampToHTMLDate = (timestamp) => {
  if(timestamp){
    const date = new Date(timestamp.seconds * 1000);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const _date = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${_date}`;
  }  
  return null;
}

export const HTMLDateToFirbaseTimestamp = (date) => {
  const [year, month, _date] = date.split("-");
  return firebase.firestore.Timestamp.fromDate(new Date(year, month - 1, _date));
}

const baseUrl = "https://ssju.ac.in/apis"

export const api = `${baseUrl}/api`

export const generatePublicUrl = (filename) => {
  return `${baseUrl}/public/${filename}`
}

export const printINR= (cost) => {
  return new Intl.NumberFormat("en-IN", {style: "currency", currency: "INR", maximumSignificantDigits: 3}).format(cost)
}

export const getFirebaseTimestamp = () => {
  return firebase.firestore.Timestamp.fromDate(new Date());
}

export const uploadImageToFirebase = async (file, path) => {
  let fileName = new Date().toISOString().replace(/[-:.]/g,"")+".jpg";
  let uploadTask = storage.ref(`${path}/${fileName}`).put(file);
  let downloadUrl = await new Promise((resolve, reject) => {
    uploadTask.on("state_changed", (snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      console.log("Upload: ", fileName, "-" , progress);
    }, (error) => {
      alert(error.message);
      reject(error);
    }, () => {      
      storage.ref(path).child(fileName).getDownloadURL().then(url => {
        resolve(url);
      })
    });
  });
  return downloadUrl;
}

export const uploadPdfToFirebase = async (file, path) => {
  let fileName = new Date().toISOString().replace(/[-:.]/g,"")+"-"+file.name;
  let uploadTask = storage.ref(`${path}/${fileName}`).put(file);
  let downloadUrl = await new Promise((resolve, reject) => {
    uploadTask.on("state_changed", (snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      console.log("Upload: ", fileName, "-" , progress);
    }, (error) => {
      alert(error.message);
      reject(error);
    }, () => {      
      storage.ref(path).child(fileName).getDownloadURL().then(url => {
        resolve(url);
      })
    });
  });
  return downloadUrl;
}