import { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { BsFillCalendar2DateFill } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { IoMdLink } from "react-icons/io";
import { MdDeleteOutline, MdOutlineDelete, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import slugify from "slugify";
import * as Yup from "yup";
import { FormControlLabel, Switch } from "@material-ui/core";

import { Box, Button, Flex, Input, Loader, Text } from "atoms";
import { DeleteConfirmationModal } from "molecules";
import { getEntranceExams } from "redux/actions";
import { db } from "utils/firebase";
import { firbaseTimestampToHTMLDate, formatDate, HTMLDateToFirbaseTimestamp } from "utils/utilities";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"), 
  year: Yup.string()
    .required("Year is required")
    .min(4, "Year must be at least 4 characters"),    
});

export const EntranceExamsPage = () => {

  const [ loading, setLoading ] = useState(false);    
  const [ entranceExam, setEntranceExam ] = useState(null);
  const [ editMode, setEditMode ] = useState(false);
  const [ forDelete, setForDelete ] = useState(null);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);

  const dispatch = useDispatch();
  const { entranceExams, loading: reducerLoading } = useSelector(state => state.app);

  useEffect(() => {
    if(entranceExams.length === 0)  dispatch(getEntranceExams());    
  }, [dispatch]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);    
    let exam = {
      name: values.name,
      year: values.year,      
    } 
    if(values.applyLink !== "") exam.applyLink = values.applyLink;
    if(values.admitCardLink !== "") exam.admitCardLink = values.admitCardLink;
    if(values.closed === true) exam.closed = values.closed;
    if(values.closed === false) exam.closed = false;
    if(values.otherDocs.length > 0) exam.otherDocs = values.otherDocs;

    if(values.startDate !== "") exam.startDate = HTMLDateToFirbaseTimestamp(values.startDate);
    if(values.endDate !== "") exam.endDate = HTMLDateToFirbaseTimestamp(values.endDate);
    if(values.admitCardDate !== "") exam.admitCardDate = HTMLDateToFirbaseTimestamp(values.admitCardDate);
    if(values.examDate !== "") exam.examDate = HTMLDateToFirbaseTimestamp(values.examDate);    
    if(editMode){
      await db.collection("entranceExams").doc(entranceExam.id).update(exam);
      toast.success("Entrance Exam Updated Successfully!");
    }else{
      let name = slugify(values.name, {lower: true, strict: true}) + "-" + values.year;      
      await db.collection("entranceExams")
        .doc(name)
        .set(exam)
        .then((docRef) => {
          toast.success("Entrance Exam Added successfully!");          
        })
        .catch((error) => {          
          toast.error("Something went wrong while adding exam");
        });
    }    
    setEntranceExam(null);
    setEditMode(false);
    resetForm();
    setLoading(false);
    setSubmitting(false);          
  }

  const onDeleteExam = async () => {
    setDeleteModalOpen(false);
    setLoading(true);
    await db.collection("entranceExams").doc(forDelete.id).delete();
    toast.success("Entrance Exam Deleted Successfully!");
    setForDelete(null);    
    setLoading(false);
  }

  return (
    <>
      {deleteModalOpen && 
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          data={{
            name: forDelete.name + "-" + forDelete.year,
            module: "Entrance Exam",
          }}            
          onRequestClose={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          ariaHideApp={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          onConfirm={() => onDeleteExam()}
        />
      }
      {(loading || reducerLoading) && <Loader />}
      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
        id="form"
      >        
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="1.8rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
            color="primary.200"
          >
            {editMode ? "Update" : "Add"} Entrance Exams
          </Text>          
        </Flex> 

        <Box my="2rem">
          <Formik
            initialValues={{              
              name: editMode ? entranceExam?.name : "",
              year: editMode ? entranceExam?.year : "",
              applyLink: editMode ? entranceExam?.applyLink || "" : "",
              admitCardLink: editMode ? entranceExam?.admitCardLink || "" : "",
              startDate: editMode ? firbaseTimestampToHTMLDate(entranceExam?.startDate) || "" : "",
              endDate: editMode ? firbaseTimestampToHTMLDate(entranceExam?.endDate) || "" : "",
              admitCardDate: editMode ? firbaseTimestampToHTMLDate(entranceExam?.admitCardDate) || "" : "",
              examDate: editMode ? firbaseTimestampToHTMLDate(entranceExam?.examDate) || "" : "",
              otherDocs: editMode ? entranceExam?.otherDocs || [] : [],
              closed: editMode ? entranceExam?.closed || false : false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ touched, errors, setFieldValue, values, resetForm }) => {
              return (                  
                <Form>  
                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                  >
                    <Box mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="name"
                        type="text"
                        label="Exam Name*"                        
                        id="name"
                        icon={<MdOutlineTitle />}
                        iconColor={errors.name ? "danger.100" : "primary.200"}
                        touched={touched.name}
                        errors={errors.name}                            
                      />   
                    </Box>
                    <Box mt={{xs: "2rem", md: 0}} mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="year"
                        type="text"
                        label="Exam Year*"                        
                        id="year"
                        icon={<BsFillCalendar2DateFill />}
                        iconColor={errors.year ? "danger.100" : "primary.200"}
                        touched={touched.year}
                        errors={errors.year}                            
                      />   
                    </Box>  
                    <Flex mt={{xs: "2rem", md: 0}} flex="1">
                      <Box mr="3rem" mt="1.5rem">
                        <FormControlLabel 
                          control={
                            <Switch                                    
                              checked={values.closed}
                              name="closed"
                              color="secondary"
                              onChange={(e) => setFieldValue("closed", e.target.checked)}                            
                            />
                          }
                          label='Form Closed?'
                        />
                      </Box>
                      <Box>
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={() => setFieldValue("otherDocs", [...values.otherDocs, {href: "", title: ""}])}
                          p="1rem 2rem"
                          borderRadius="0.5rem"
                          width="fit-content"
                          mt="1.5rem"
                        >
                          Add Documents
                        </Button>
                      </Box>
                    </Flex>
                  </Flex>                                                                                                                                              

                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                  >
                    <Box mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="startDate"
                        type="date"
                        label="Exam Form Start Date"                        
                        id="startDate"
                        icon={<BsFillCalendar2DateFill />}
                        iconColor={errors.startDate ? "danger.100" : "primary.200"}
                        touched={touched.startDate}
                        errors={errors.startDate}                            
                      />   
                    </Box>
                    <Box mt={{xs: "2rem", md: 0}} mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="endDate"
                        type="date"
                        label="Exam Form End Date"                        
                        id="endDate"
                        icon={<BsFillCalendar2DateFill />}
                        iconColor={errors.endDate ? "danger.100" : "primary.200"}
                        touched={touched.endDate}
                        errors={errors.endDate}                            
                      />   
                    </Box> 

                    <Box mt={{xs: "2rem", md: 0}} flex="1">
                      <Input
                        name="examDate"
                        type="date"
                        label="Exam Date"                        
                        id="examDate"
                        icon={<BsFillCalendar2DateFill />}
                        iconColor={errors.examDate ? "danger.100" : "primary.200"}
                        touched={touched.examDate}
                        errors={errors.examDate}                            
                      />   
                    </Box>  
                  </Flex>   

                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                  >
                    <Box mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="applyLink"
                        type="text"
                        label="Apply URL"                        
                        id="applyLink"
                        icon={<IoMdLink />}
                        iconColor={errors.applyLink ? "danger.100" : "primary.200"}
                        touched={touched.applyLink}
                        errors={errors.applyLink}                            
                      />   
                    </Box>
                    <Box mt={{xs: "2rem", md: 0}} mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="admitCardLink"
                        type="text"
                        label="Admit Card URL"                        
                        id="admitCardLink"
                        icon={<IoMdLink />}
                        iconColor={errors.admitCardLink ? "danger.100" : "primary.200"}
                        touched={touched.admitCardLink}
                        errors={errors.admitCardLink}                            
                      />   
                    </Box> 

                    <Box mt={{xs: "2rem", md: 0}} flex="1">
                      <Input
                        name="admitCardDate"
                        type="date"
                        label="Admit Card Date"                        
                        id="admitCardDate"
                        icon={<BsFillCalendar2DateFill />}
                        iconColor={errors.admitCardDate ? "danger.100" : "primary.200"}
                        touched={touched.admitCardDate}
                        errors={errors.admitCardDate}                            
                      />   
                    </Box>  
                  </Flex> 

                  <FieldArray
                    name="otherDocs"
                    render={(arrayHelpers) => (
                      <Box>
                        {values.otherDocs.map((doc, index) => (
                          <Flex
                            key={index}
                            flexDirection={{ xs: "column", md: "row" }}
                            mb="1rem"
                          >
                            <Box mr={{md: "2rem"}}>
                              <Input
                                name={`otherDocs.${index}.title`}
                                type="text"
                                label={"Document Title " + (index+1) + "*"}
                                id={`otherDocs.${index}.title`}
                                icon={<MdOutlineTitle />}
                                iconColor={errors.otherDocs ? "danger.100" : "primary.200"}
                                touched={touched.otherDocs}
                                errors={errors.otherDocs}                                                                
                              />
                            </Box>
                            <Box mr={{md: "2rem"}}>
                              <Input
                                name={`otherDocs.${index}.href`}
                                type="text"
                                label={"Documet URL " + (index+1) + "*"}
                                id={`otherDocs.${index}.href`}
                                icon={<IoMdLink />}
                                iconColor={errors.otherDocs ? "danger.100" : "primary.200"}
                                touched={touched.otherDocs}
                                errors={errors.otherDocs}                                                                
                              />
                            </Box>                      
                            <Box
                              width="fit-content"
                              p="1rem 2rem"
                              borderRadius="0.5rem"
                              type="button"                          
                              onClick={() => arrayHelpers.remove(index)}
                              color="danger.100"
                              mt={{md: "1.5rem"}}
                              cursor="pointer"
                              mx={{xs: "auto", md: "0"}}
                            >
                              <MdOutlineDelete
                                fontSize="2rem"
                              />
                            </Box>                      
                          </Flex>
                        ))}
                      </Box>
                    )}
                  />                  
                  <Flex justifyContent="space-between">
                    <Button
                      variant="warning"                    
                      px="2rem"
                      py="1rem"
                      type="button"                    
                      mt="1rem"                                                                                   
                      borderRadius="0.5rem"
                      width="fit-content"
                      onClick={() => {                        
                        setEditMode(false);
                        setEntranceExam(null);                        
                        resetForm()
                      }}
                    >
                      <Text fontSize="1.4rem" fontWeight="400">
                        Reset
                      </Text>
                    </Button>
                    <Button
                      variant="success"                    
                      px="2rem"
                      py="1rem"
                      type="submit"                    
                      mt="1rem"                                       
                      loading={loading}
                      textTransform="uppercase"
                      borderRadius="0.5rem"
                      width="fit-content"
                    >
                      <Text fontSize="1.6rem" fontWeight="500">
                        {editMode ? "Update" : "Add"} Entrance Exam
                      </Text>
                    </Button>                    
                  </Flex>
                </Form>
              )
            }}
          </Formik>
        </Box>
      </Box>

      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
        mt="4rem"
      >        
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="1.8rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
            color="primary.200"
          >
            Manage Entrance Exams
          </Text>          
        </Flex> 

        <Flex my="2rem" 
          alignItems="start"
          flexWrap="wrap"
          justifyContent="space-between"
        >          
          {entranceExams.map((exam, index) => (
            <Box
              key={index}
              width={{xs: "32rem", md: "50rem"}}
              p="2rem"
              borderRadius="0.5rem"
              border="1px solid whitesmoke"
              bg={exam?.closed ? "#F9C5C5" : "#C1F9C9"}
              mr="2rem"
              mb="2rem"
              height="100%"
            >
              <Flex
                justifyContent="space-between"
              >
                <Flex alignItems="center">
                  <Box                      
                    width="1.5rem"
                    height="1.5rem"
                    borderRadius="50%"
                    bg={exam?.closed ? "danger.100" : "success.100"}
                    mr="1rem"
                  /> 
                  <Text>                  
                    {exam?.name} {" "} {exam?.year}
                  </Text>
                </Flex>
                <Flex>
                  <Box
                    fontSize="2rem"
                    color="warning.100"
                    mr="1.5rem"
                    cursor="pointer"
                    onClick={() => {
                      setEntranceExam(exam);
                      setEditMode(true);                       
                      window.location.href = "#form";                     
                    }}
                  >
                    <FiEdit2/>
                  </Box>
                  <Box
                    fontSize="2rem"
                    color="danger.100"
                    cursor="pointer"
                    onClick={() => {
                      setForDelete(exam);
                      setDeleteModalOpen(true);
                    }}
                  >
                    <MdDeleteOutline/>
                  </Box>
                </Flex>
              </Flex>
              <Box mt="1rem" pl="2.5rem">                              
                <Text py="0.5rem">                  
                  Start Date: {exam?.startDate ? formatDate(exam?.startDate) : "Declaring Soon"}
                </Text>
                <Text py="0.5rem">
                  End Date: {exam?.endDate ? formatDate(exam?.endDate) : "Declaring Soon"}
                </Text>
                <Text py="0.5rem">
                  Admit Card Date: {exam?.admitCardDate ? formatDate(exam?.admitCardDate) : "Declaring Soon"}
                </Text>
                <Text py="0.5rem">
                  Exam Date: {exam?.examDate ? formatDate(exam?.examDate) : "Declaring Soon"}
                </Text>
                <Flex
                  alignItems="center"
                  my="1rem"
                  justifyContent="space-between"                  
                >
                  {exam?.admitCardLink && (
                    <a href={exam.admitCardLink} target="_blank">
                      <Text color="info.200" border="1px solid" borderColor="info.200" borderRadius="0.5rem" p="1rem">
                        Admit Card Link
                      </Text>
                    </a>
                  )}
                  {exam?.applyLink && (
                    <a href={exam.applyLink} target="_blank">
                      <Text color="danger.100" border="1px solid" borderColor="danger.100" borderRadius="0.5rem" p="1rem">
                        Apply Link
                      </Text>
                    </a>
                  )}
                </Flex>
                {exam?.otherDocs && <Text my="1rem">Documents</Text>}               
                <Flex
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {exam?.otherDocs?.map((doc, index) => (
                    <Box 
                      key={index}
                      bg="warning.200"
                      p="1rem 2rem"
                      width="fit-content"
                      borderRadius="0.5rem"
                      color="white"
                      mr="2rem"
                      mb="2rem"
                    >
                      <a href={doc.href}>
                        <Text>
                          {doc.title}
                        </Text>
                      </a>
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Box>
          ))}
        </Flex>   
      </Box>
    </>
  )
}