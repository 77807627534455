import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { Loader } from "atoms";
import Dashboard from "pages/dashboard";
import ErrorPage from "pages/error-page";
import News from "pages/news";
import Events from "pages/events";
import Sports from "pages/sports";
import Gallery from "pages/gallery";
import ExamSchedule from "pages/exam-schedule";
import EntranceSyllabus from "pages/entrance-syllabus";
import NEPSyllabus from "pages/nep-syllabus";
import Recruitments from "pages/recruitments";
import Faculties from "pages/faculties";
import EntranceExams from "pages/entrance-exams";
import ExamForms from "pages/exam-forms";
import ExamResults from "pages/exam-results";
import ImportantLinks from "pages/important-links";
import Tools from "pages/tools";
import Admissions from "pages/admissions";

export const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" exact element={<Navigate to="/dashboard" replace />} />        
        <Route path="/dashboard" exact element={<Dashboard />} />  
        <Route path="/news" exact element={<News />} />  
        <Route path="/events" exact element={<Events />} />  
        <Route path="/sports" exact element={<Sports />} />  
        <Route path="/gallery" exact element={<Gallery />} /> 
        <Route path="/exam-schedule" exact element={<ExamSchedule />} />  
        <Route path="/entrance-syllabus" exact element={<EntranceSyllabus />} />  
        <Route path="/nep-syllabus" exact element={<NEPSyllabus />} />  
        <Route path="/recruitments" exact element={<Recruitments />} />  
        <Route path="/faculties" exact element={<Faculties/>} />
        <Route path="/entrance-exams" exact element={<EntranceExams />} />
        <Route path="/exam-forms" exact element={<ExamForms />} />
        <Route path="/exam-results" exact element={<ExamResults />} />
        <Route path="/important-links" exact element={<ImportantLinks />} />
        <Route path="/admissions" exact element={<Admissions />} />
        <Route path="/tools" exact element={<Tools />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
};