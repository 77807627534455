import { Layout } from "organisms";
import { GalleryPage } from "templates";

const Gallery = () => {

  document.title = "Gallery | ADMIN SSJU";

  return (
    <Layout>
      <GalleryPage/>
    </Layout>
  )
}

export default Gallery