import { Layout } from "organisms";
import { EntranceSyllabusPage } from "templates";

const EntranceSyllabus = () => {

  document.title = "Entrance Syllabus | ADMIN SSJU";

  return (
    <Layout>
      <EntranceSyllabusPage/>
    </Layout>
  )
}

export default EntranceSyllabus