import { Layout } from "organisms";
import { ImportantLinksPage } from "templates";

const ImportantLinks = () => {

  document.title = "Important Links | ADMIN SSJU";

  return (
    <Layout>
      <ImportantLinksPage/>
    </Layout>
  )
}

export default ImportantLinks