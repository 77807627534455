import React from "react";
import { ErrorMessage, Field } from "formik";

import Box from "../Box";
import { Error } from "../Error";
import { Flex } from "../Flex";
import Text from "../Text";

export const Input = ({
  name,
  type,  
  placeholder,
  id,
  icon,
  iconColor,
  touched,
  errors,
  textArea,
  rows,
  disabled=false,
  lessMB,
  label
}) => {
  return (
    <>
      {
        label && <Text
          as="label"
          htmlFor={id}
          fontSize="1.4rem"
          fontWeight="400"
          color="grey.300"          
        >
          {label}
        </Text>
      }
      <Box mb={lessMB ? "0.8rem" : "2rem"} mt={label ? "-0.8rem" : "unset"}>        
        {
          icon && <Flex
          position="absolute"
          left="1rem"
          height="3.8rem"
          alignItems="center"
          justifyContent="center"
          fontSize="1.4rem"           
          color={touched && !errors ? "primary.200" : iconColor}                   
        >
          {icon}
        </Flex>
        }
        {
          textArea ? <Field
            as="textarea"
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            rows={rows}
            disabled={disabled}
            className={
              touched && errors
                ? "input-error"
                : ""
            }        
            style={{
              borderColor: touched && !errors
                ? "#0BBA7A"
                : ""
            }}
          />      
          : <Field
            type={type}
            id={id}
            name={name}
            placeholder={placeholder}
            onWheel={(e) => e.target.blur()}
            disabled={disabled}
            className={
              touched && errors
                ? "input-error"
                : ""
            }        
            style={{
              borderColor: touched && !errors
                ? "#0BBA7A"
                : ""
            }}
          />
        }
        <ErrorMessage name={name} component={Error} />
      </Box>
    </>
  )
}