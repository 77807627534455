import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { BiDetail } from "react-icons/bi";
import { IoMdLink } from "react-icons/io";
import { MdOutlineTitle } from "react-icons/md";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FormControlLabel, Switch } from "@material-ui/core";

import { Box, Button, Flex, Input, Loader, Text } from "atoms";
import { db } from "utils/firebase";
import { getFirebaseTimestamp } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"),
  desc: Yup.string()
    .required("Description is required")
    .min(3, "Description must be at least 3 characters"),
});

export const EventsPage = () => {

  const [ loading, setLoading ] = useState(false);  
  const [ error, setError ] = useState(null); 

  useEffect(() => {
    if(error){
      toast.error(error);
      setError(null);
    }       
  }, [loading, error]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);     
    let event = {
      title: values.title,
      desc: values.desc,            
      timestamp: getFirebaseTimestamp(),
    }
    if(values.eventSrc){
      event.eventSrc = values.eventSrc;
      event.srcTitle = values.srcTitle
      event.srcFileUrl = values.srcFileUrl
    }        
    db.collection("events").add(event);
    toast.success("Event Added Successfully!");    
    resetForm();
    setLoading(false);
    setSubmitting(false);          
  } 

  return (
    <Box
      width="100%"
      bg="white"
      p="2rem"
      borderRadius="0.5rem"
    >
      {loading && <Loader />}
      <Text
        fontSize="1.8rem"
        fontWeight="600"
        mb="1rem"
        textTransform="uppercase"
        color="primary.200"
      >
        Add Event
      </Text>
      <Box my="2rem">
          <Formik
            initialValues={{              
              title: "",
              desc: "",
              eventSrc: false,
              srcTitle: "",
              srcFileUrl: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ touched, errors, values, setFieldValue }) => {
              return (                  
                <Form>  
                  <Input
                    name="title"
                    type="text"
                    label="Event Title*"                        
                    id="title"
                    icon={<MdOutlineTitle />}
                    iconColor={errors.title ? "danger.100" : "primary.200"}
                    touched={touched.title}
                    errors={errors.title}                            
                  />                  
                  <Input
                    name="desc"
                    type="text"                    
                    label="Event Description*"
                    id="desc"
                    icon={<BiDetail />}
                    iconColor={errors.desc ? "danger.100" : "primary.200"}
                    touched={touched.desc}
                    errors={errors.desc}
                    textArea
                    rows={4}
                  />                                       
                  <Flex
                    flexDirection={{ xs: "column", md: "row" }}
                    mb="2rem"
                  >                                        
                    <FormControlLabel 
                      control={
                        <Switch
                          name='eventSrc'
                          color='secondary'
                          onChange={(e) => setFieldValue("eventSrc", e.target.checked)}                            
                        />
                      }
                      label='Event has Src?'
                    />
                    {values.eventSrc && (
                      <>
                        <Box mr={{md: "2rem"}}>
                          <Input
                            name="srcTitle"
                            type="text"
                            label={"Source Title"}
                            id="srcTitle"
                            icon={<MdOutlineTitle />}
                            iconColor={errors.srcTitle ? "danger.100" : "primary.200"}
                            touched={touched.srcTitle}
                            errors={errors.srcTitle}                                                                
                          />
                        </Box>
                        <Box mr={{md: "2rem"}}>
                          <Input
                            name="srcFileUrl"
                            type="text"
                            label={"Source URL"}
                            id="srcFileUrl"
                            icon={<IoMdLink />}
                            iconColor={errors.srcFileUrl ? "danger.100" : "primary.200"}
                            touched={touched.srcFileUrl}
                            errors={errors.srcFileUrl}                                                                
                          />
                        </Box>  
                      </>
                    )}                    
                  </Flex>                                  

                  <Button
                    variant="primary"                    
                    px="2rem"
                    py="1rem"
                    type="submit"                    
                    mt="1rem"                                       
                    loading={loading}
                    textTransform="uppercase"
                    borderRadius="0.5rem"
                    width="fit-content"
                  >
                    <Text fontSize="1.6rem" fontWeight="500">
                      Add Event
                    </Text>
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </Box>
    </Box>
  )
}