import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";

import store from "redux/store";
import { AppRoutes } from "routes";
import Login from "pages/login";
import { theme } from "styles";
import { auth } from "utils/firebase";

import "react-toastify/dist/ReactToastify.css";
import "styles/globals.css";
import "styles/style.css";

function App() {      

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if(authUser){
        setUser(authUser);
      }else{
        setUser(null);
      }
    });
    return () => {
      unsubscribe();
    }
  }, [user]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>  
        <ToastContainer         
          style={{ fontSize: "1.4rem" }}        
        />
        {
          user ? <Router>
            <AppRoutes/>
          </Router> : <Login/>
        }                    
      </ThemeProvider>
    </Provider>
  );
}

export default App;