import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { BiLinkAlt } from "react-icons/bi";
import { MdOutlineTitle } from "react-icons/md";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Input, Loader, Text } from "atoms";
import { db } from "utils/firebase";
import { getFirebaseTimestamp } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"),
  href: Yup.string()
    .required("URL is required")
    .min(6, "URL must be at least 6 characters"),
});

export const EntranceSyllabusPage = () => {

  const [ loading, setLoading ] = useState(false);  
  const [ error, setError ] = useState(null); 

  useEffect(() => {
    if(error){
      toast.error(error);
      setError(null);
    }       
  }, [loading, error]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);     
    let syllabus = {
      syllabusTitle: values.title,
      syllabusUrl: values.href,            
      timestamp: getFirebaseTimestamp(),
    }            
    db.collection("entranceSyllabus").add(syllabus);
    toast.success("Entrance Syllabus Added Successfully!");    
    resetForm();
    setLoading(false);
    setSubmitting(false);          
  } 

  return (
    <Box
      width="100%"
      bg="white"
      p="2rem"
      borderRadius="0.5rem"
    >
      {loading && <Loader />}
      <Text
        fontSize="1.8rem"
        fontWeight="600"
        mb="1rem"
        textTransform="uppercase"
        color="primary.200"
      >
        Add Entrance Exam Syllabus
      </Text>
      <Box my="2rem">
        <Formik
          initialValues={{              
            title: "",
            href: "",              
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors }) => {
            return (                  
              <Form>  
                <Input
                  name="title"
                  type="text"
                  label="Syllabus Title*"                        
                  id="title"
                  icon={<MdOutlineTitle />}
                  iconColor={errors.title ? "danger.100" : "primary.200"}
                  touched={touched.title}
                  errors={errors.title}                            
                />   

                <Input
                  name="href"
                  type="text"
                  label="Syllabus URL*"                        
                  id="href"
                  icon={<BiLinkAlt />}
                  iconColor={errors.href ? "danger.100" : "primary.200"}
                  touched={touched.href}
                  errors={errors.href}                            
                />                                                                                                                         

                <Button
                  variant="primary"                    
                  px="2rem"
                  py="1rem"
                  type="submit"                    
                  mt="1rem"                                       
                  loading={loading}
                  textTransform="uppercase"
                  borderRadius="0.5rem"
                  width="fit-content"
                >
                  <Text fontSize="1.6rem" fontWeight="500">
                    Add Syllabus
                  </Text>
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}