import { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { BiLinkAlt } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { MdDeleteOutline, MdOutlineDelete, MdOutlineTitle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import slugify from "slugify";
import * as Yup from "yup";
import { FormControlLabel, Switch } from "@material-ui/core";

import { Box, Button, Flex, Input, Loader, Text } from "atoms";
import { DeleteConfirmationModal } from "molecules";
import { getExamResults } from "redux/actions";
import { db } from "utils/firebase";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Title is required")
    .min(2, "Title must be at least 2 characters")  
});

export const ExamResultsPage = () => {

  const [ loading, setLoading ] = useState(false);    
  const [ examResult, setExamResult ] = useState(null);
  const [ editMode, setEditMode ] = useState(false);
  const [ forDelete, setForDelete ] = useState(null);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);

  const dispatch = useDispatch();
  const { examResults, loading: reducerLoading } = useSelector(state => state.app);

  useEffect(() => {
    if(examResults.length === 0)  dispatch(getExamResults());    
  }, [dispatch]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);    
    let result = {
      name: values.name,      
    }         
    if(values.courses.length > 0) result.courses = values.courses;    
    if(values.hide === true) result.hide = values.hide;
    if(values.hide === false) result.hide = false;
    if(editMode){
      await db.collection("results").doc(examResult.id).update(result);
      toast.success("Result Updated Successfully!");
    }else{
      let name = slugify(values.name, {lower: true, strict: true});      
      await db.collection("results")
        .doc(name)
        .set(result)
        .then((docRef) => {
          toast.success("Result Added successfully!");          
        })
        .catch((error) => {          
          toast.error("Something went wrong while adding exam");
        });
    }    
    setExamResult(null);
    setEditMode(false);
    resetForm();
    setLoading(false);
    setSubmitting(false);          
  }

  const onDeleteExamResult = async () => {
    setDeleteModalOpen(false);
    setLoading(true);
    await db.collection("results").doc(forDelete.id).delete();
    toast.success("Result Deleted Successfully!");
    setForDelete(null);    
    setLoading(false);
  }

  return (
    <>
      {deleteModalOpen && 
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          data={{
            name: forDelete.name,
            module: "Exam Result",
          }}            
          onRequestClose={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          ariaHideApp={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          onConfirm={() => onDeleteExamResult()}
        />
      }
      {(loading || reducerLoading) && <Loader />}

      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
        id="form"
      >        
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="1.8rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
            color="primary.200"
          >
            {editMode ? "Update" : "Add"} Exam Result
          </Text>          
        </Flex> 

        <Box my="2rem">
          <Formik
            initialValues={{              
              name: editMode ? examResult?.name : "",              
              courses: editMode ? examResult?.courses || [] : [],              
              hide: editMode ? examResult?.hide || false : false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ touched, errors, setFieldValue, values, resetForm }) => {
              return (                  
                <Form>  
                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                  >
                    <Box mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="name"
                        type="text"
                        label="Exam Name*"                        
                        id="name"
                        icon={<MdOutlineTitle />}
                        iconColor={errors.name ? "danger.100" : "primary.200"}
                        touched={touched.name}
                        errors={errors.name}                            
                      />   
                    </Box>                      
                    <Flex mt={{xs: "2rem", md: 0}} flex="1">
                      <Box mr="3rem" mt="1.5rem">
                        <FormControlLabel 
                          control={
                            <Switch                                    
                              checked={values.hide}
                              name="hide"
                              color="secondary"
                              onChange={(e) => setFieldValue("hide", e.target.checked)}                            
                            />
                          }
                          label='Hide Result?'
                        />
                      </Box>   
                      <Box>
                        <Button
                          type="button"
                          variant="primary"
                          onClick={() => setFieldValue("courses", [...values.courses, {name: "", href: ""}])}
                          p="1rem 2rem"
                          borderRadius="0.5rem"
                          width="fit-content"
                          mt="1.5rem"
                        >
                          Add Courses
                        </Button>
                      </Box>                   
                    </Flex>
                  </Flex>                                                                                                                                                                
                  <Box                                        
                    border="1px solid #e2e8f0"
                    p="2rem 1rem"
                    borderRadius="0.5rem"
                  >
                    <FieldArray
                      name="courses"
                      render={(arrayHelpers) => (
                        <Flex
                          flexWrap="wrap"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {values.courses.map((course, index) => (
                            <Flex
                              key={index}
                              flexDirection={{ xs: "column", md: "row" }}
                              mb="1rem"
                              maxWidth="55rem"                              
                            >
                              <Box mr={{md: "2rem"}}>
                                <Input
                                  name={`courses.${index}.name`}
                                  type="text"
                                  label={"Course Name " + (index+1) + "*"}
                                  id={`courses.${index}.name`}
                                  icon={<MdOutlineTitle />}
                                  iconColor={errors.courses ? "danger.100" : "primary.200"}
                                  touched={touched.courses}
                                  errors={errors.courses}                                                                
                                />
                              </Box>
                              <Box mr={{md: "2rem"}}>
                                <Input
                                  name={`courses.${index}.href`}
                                  type="text"
                                  label={"Result URL " + (index+1) + "*"}
                                  id={`courses.${index}.href`}
                                  icon={<BiLinkAlt />}
                                  iconColor={errors.courses ? "danger.100" : "primary.200"}
                                  touched={touched.courses}
                                  errors={errors.courses}                                                                
                                />
                              </Box>                      
                              <Box
                                width="fit-content"
                                p="1rem 2rem"
                                borderRadius="0.5rem"
                                type="button"                          
                                onClick={() => arrayHelpers.remove(index)}
                                color="danger.100"
                                mt={{md: "1.5rem"}}
                                cursor="pointer"
                                mx={{xs: "auto", md: "0"}}
                              >
                                <MdOutlineDelete
                                  fontSize="2rem"
                                />
                              </Box>                      
                            </Flex>
                          ))}
                        </Flex>
                      )}
                    />   
                  </Box>                                 
                  <Flex justifyContent="space-between">
                    <Button
                      variant="warning"                    
                      px="2rem"
                      py="1rem"
                      type="button"                    
                      mt="1rem"                                                                                   
                      borderRadius="0.5rem"
                      width="fit-content"
                      onClick={() => {                        
                        setEditMode(false);
                        setExamResult(null);                        
                        resetForm()
                      }}
                    >
                      <Text fontSize="1.4rem" fontWeight="400">
                        Reset
                      </Text>
                    </Button>
                    <Button
                      variant="success"                    
                      px="2rem"
                      py="1rem"
                      type="submit"                    
                      mt="1rem"                                       
                      loading={loading}
                      textTransform="uppercase"
                      borderRadius="0.5rem"
                      width="fit-content"
                    >
                      <Text fontSize="1.6rem" fontWeight="500">
                        {editMode ? "Update" : "Add"} Exam Result
                      </Text>
                    </Button>                    
                  </Flex>
                </Form>
              )
            }}
          </Formik>
        </Box>        
      </Box>

      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
        mt="4rem"
      >        
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="1.8rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
            color="primary.200"
          >
            Manage Exam Results
          </Text>          
        </Flex> 

        <Flex my="2rem" 
          alignItems="start"
          flexWrap="wrap"
        >          
          {examResults.map((result, index) => (
            <Box
              key={index}
              width={{xs: "32rem", md: "50rem"}}
              p="2rem"
              borderRadius="0.5rem"
              border="1px solid whitesmoke"
              bg={result?.hide ? "#F9C5C5" : "#C1F9C9"}
              mr="2rem"
              mb="2rem"
              height="100%"
            >
              <Flex
                justifyContent="space-between"
              >
                <Flex alignItems="center">
                  <Box                      
                    width="1.5rem"
                    height="1.5rem"
                    borderRadius="50%"
                    bg={result?.hide ? "danger.100" : "success.100"}
                    mr="1rem"
                  /> 
                  <Text>                  
                    {result?.name}
                  </Text>
                </Flex>
                <Flex>
                  <Box
                    fontSize="2rem"
                    color="warning.100"
                    mr="1.5rem"
                    cursor="pointer"
                    onClick={() => {
                      setExamResult(result);
                      setEditMode(true);                       
                      window.location.href = "#form";                     
                    }}
                  >
                    <FiEdit2/>
                  </Box>
                  <Box
                    fontSize="2rem"
                    color="danger.100"
                    cursor="pointer"
                    onClick={() => {
                      setForDelete(result);
                      setDeleteModalOpen(true);
                    }}
                  >
                    <MdDeleteOutline/>
                  </Box>
                </Flex>
              </Flex>
              <Box mt="1rem" pl="2.5rem">
                <Box>
                {result?.courses.map((course, i) => (
                  <Box mb="1rem">
                    <a
                      key={i}
                      href={course?.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text
                        fontWeight="500"
                      >
                        {i+1} - {course?.name}
                      </Text>
                    </a>
                  </Box>
                ))}   
                </Box>                           
              </Box>
            </Box>
          ))}
        </Flex>   
      </Box>
    </>
  )
}