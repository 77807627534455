import { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { BsFillCalendar2DateFill } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import { IoMdLink } from "react-icons/io";
import { MdDeleteOutline, MdOutlineDelete, MdOutlineTitle } from "react-icons/md";
import { TbCurrencyRupee } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import slugify from "slugify";
import * as Yup from "yup";
import { FormControlLabel, Switch } from "@material-ui/core";

import { Box, Button, Flex, Input, Loader, Text } from "atoms";
import { DeleteConfirmationModal } from "molecules";
import { getExamForms } from "redux/actions";
import { db } from "utils/firebase";
import { firbaseTimestampToHTMLDate, formatDate, HTMLDateToFirbaseTimestamp, printINR } from "utils/utilities";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"), 
  year: Yup.string()
    .required("Year is required")
    .min(4, "Year must be at least 4 characters"),    
});

export const ExamFormsPage = () => {

  const [ loading, setLoading ] = useState(false);    
  const [ examForm, setExamForm ] = useState(null);
  const [ editMode, setEditMode ] = useState(false);
  const [ forDelete, setForDelete ] = useState(null);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);

  const dispatch = useDispatch();
  const { examForms, loading: reducerLoading } = useSelector(state => state.app);

  useEffect(() => {
    if(examForms.length === 0)  dispatch(getExamForms());    
  }, [dispatch]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true);    
    let exam = {
      name: values.name,
      year: values.year,      
    }     
    if(values.hide === true) exam.hide = values.hide;
    if(values.hide === false) exam.hide = false;
    if(values.fees.length > 0) exam.fees = values.fees;
    if(values.links.length > 0) exam.links = values.links;

    if(values.startDate !== "") exam.startDate = HTMLDateToFirbaseTimestamp(values.startDate);
    if(values.endDate !== "") exam.endDate = HTMLDateToFirbaseTimestamp(values.endDate);
    
    if(editMode){
      await db.collection("exam-form").doc(examForm.id).update(exam);
      toast.success("Exam Form Updated Successfully!");
    }else{
      let name = slugify(values.name, {lower: true, strict: true}) + "-" + values.year;      
      await db.collection("exam-form")
        .doc(name)
        .set(exam)
        .then((docRef) => {
          toast.success("Exam Form Added successfully!");          
        })
        .catch((error) => {          
          toast.error("Something went wrong while adding exam");
        });
    }    
    setExamForm(null);
    setEditMode(false);
    resetForm();
    setLoading(false);
    setSubmitting(false);          
  }

  const onDeleteExamForm = async () => {
    setDeleteModalOpen(false);
    setLoading(true);
    await db.collection("exam-form").doc(forDelete.id).delete();
    toast.success("Exam Form Deleted Successfully!");
    setForDelete(null);    
    setLoading(false);
  }

  return (
    <>
      {deleteModalOpen && 
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          data={{
            name: forDelete.name + "-" + forDelete.year,
            module: "Exam Form",
          }}            
          onRequestClose={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          ariaHideApp={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          onConfirm={() => onDeleteExamForm()}
        />
      }
      {(loading || reducerLoading) && <Loader />}

      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
        id="form"
      >        
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="1.8rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
            color="primary.200"
          >
            {editMode ? "Update" : "Add"} Exam Form
          </Text>          
        </Flex> 

        <Box my="2rem">
          <Formik
            initialValues={{              
              name: editMode ? examForm?.name : "",
              year: editMode ? examForm?.year : "",
              fees: editMode ? examForm?.fees || [] : [],
              links: editMode ? examForm?.links || [] : [],              
              startDate: editMode ? firbaseTimestampToHTMLDate(examForm?.startDate) || "" : "",
              endDate: editMode ? firbaseTimestampToHTMLDate(examForm?.endDate) || "" : "",              
              hide: editMode ? examForm?.hide || false : false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ touched, errors, setFieldValue, values, resetForm }) => {
              return (                  
                <Form>  
                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                  >
                    <Box mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="name"
                        type="text"
                        label="Exam Name*"                        
                        id="name"
                        icon={<MdOutlineTitle />}
                        iconColor={errors.name ? "danger.100" : "primary.200"}
                        touched={touched.name}
                        errors={errors.name}                            
                      />   
                    </Box>
                    <Box mt={{xs: "2rem", md: 0}} mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="year"
                        type="text"
                        label="Exam Year*"                        
                        id="year"
                        icon={<BsFillCalendar2DateFill />}
                        iconColor={errors.year ? "danger.100" : "primary.200"}
                        touched={touched.year}
                        errors={errors.year}                            
                      />   
                    </Box>  
                    <Box mt={{xs: "2rem", md: 0}} flex="1">
                      <Box mr="3rem" mt="1.5rem">
                        <FormControlLabel 
                          control={
                            <Switch                                    
                              checked={values.hide}
                              name="hide"
                              color="secondary"
                              onChange={(e) => setFieldValue("hide", e.target.checked)}                            
                            />
                          }
                          label='Form Closed?'
                        />
                      </Box>                      
                    </Box>
                  </Flex>                                                                                                                                              

                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                  >
                    <Box mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="startDate"
                        type="date"
                        label="Exam Form Start Date"                        
                        id="startDate"
                        icon={<BsFillCalendar2DateFill />}
                        iconColor={errors.startDate ? "danger.100" : "primary.200"}
                        touched={touched.startDate}
                        errors={errors.startDate}                            
                      />   
                    </Box>
                    <Box mt={{xs: "2rem", md: 0}} mr={{xs: "0", md: "2rem"}} flex="1">
                      <Input
                        name="endDate"
                        type="date"
                        label="Exam Form End Date"                        
                        id="endDate"
                        icon={<BsFillCalendar2DateFill />}
                        iconColor={errors.endDate ? "danger.100" : "primary.200"}
                        touched={touched.endDate}
                        errors={errors.endDate}                            
                      />   
                    </Box> 
                    <Flex>
                      <Box>
                        <Button
                          type="button"
                          variant="secondary"
                          onClick={() => setFieldValue("fees", [...values.fees, {name: "", fee: ""}])}
                          p="1rem 2rem"
                          borderRadius="0.5rem"
                          width="fit-content"
                          mt="1.5rem"
                        >
                          Add Fees Info
                        </Button>
                      </Box>
                      <Box ml="2rem">
                        <Button
                          type="button"
                          variant="primary"
                          onClick={() => setFieldValue("links", [...values.links, {href: "", name: ""}])}
                          p="1rem 2rem"
                          borderRadius="0.5rem"
                          width="fit-content"
                          mt="1.5rem"
                        >
                          Add Links
                        </Button>
                      </Box>
                    </Flex> 
                  </Flex>                  
                  
                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                  >
                    <Box
                      flex="1"
                      mr={{xs: "0", md: "2rem"}}
                      border="1px solid #e2e8f0"
                      p="2rem 1rem"
                      borderRadius="0.5rem"
                    >
                      <FieldArray
                        name="fees"
                        render={(arrayHelpers) => (
                          <Box>
                            {values.fees.map((fee, index) => (
                              <Flex
                                key={index}
                                flexDirection={{ xs: "column", md: "row" }}
                                mb="1rem"
                              >
                                <Box mr={{md: "2rem"}}>
                                  <Input
                                    name={`fees.${index}.name`}
                                    type="text"
                                    label={"Course Name " + (index+1) + "*"}
                                    id={`fees.${index}.name`}
                                    icon={<MdOutlineTitle />}
                                    iconColor={errors.fees ? "danger.100" : "primary.200"}
                                    touched={touched.fees}
                                    errors={errors.fees}                                                                
                                  />
                                </Box>
                                <Box mr={{md: "2rem"}}>
                                  <Input
                                    name={`fees.${index}.fee`}
                                    type="text"
                                    label={"Course Fee " + (index+1) + "*"}
                                    id={`fees.${index}.fee`}
                                    icon={<TbCurrencyRupee />}
                                    iconColor={errors.fees ? "danger.100" : "primary.200"}
                                    touched={touched.fees}
                                    errors={errors.fees}                                                                
                                  />
                                </Box>                      
                                <Box
                                  width="fit-content"
                                  p="1rem 2rem"
                                  borderRadius="0.5rem"
                                  type="button"                          
                                  onClick={() => arrayHelpers.remove(index)}
                                  color="danger.100"
                                  mt={{md: "1.5rem"}}
                                  cursor="pointer"
                                  mx={{xs: "auto", md: "0"}}
                                >
                                  <MdOutlineDelete
                                    fontSize="2rem"
                                  />
                                </Box>                      
                              </Flex>
                            ))}
                          </Box>
                        )}
                      />   
                    </Box>               
                    <Box
                      flex="1"
                      mt={{xs: "2rem", md: 0}} 
                      border="1px solid #e2e8f0"
                      p="2rem 1rem"
                      borderRadius="0.5rem"                     
                    >
                      <FieldArray
                        name="links"
                        render={(arrayHelpers) => (
                          <Box>
                            {values.links.map((link, index) => (
                              <Flex
                                key={index}
                                flexDirection={{ xs: "column", md: "row" }}
                                mb="1rem"
                              >
                                <Box mr={{md: "2rem"}}>
                                  <Input
                                    name={`links.${index}.name`}
                                    type="text"
                                    label={"Link Name " + (index+1) + "*"}
                                    id={`links.${index}.name`}
                                    icon={<MdOutlineTitle />}
                                    iconColor={errors.links ? "danger.100" : "primary.200"}
                                    touched={touched.links}
                                    errors={errors.links}                                                                
                                  />
                                </Box>
                                <Box mr={{md: "2rem"}}>
                                  <Input
                                    name={`links.${index}.href`}
                                    type="text"
                                    label={"Link URL " + (index+1) + "*"}
                                    id={`links.${index}.href`}
                                    icon={<IoMdLink />}
                                    iconColor={errors.links ? "danger.100" : "primary.200"}
                                    touched={touched.links}
                                    errors={errors.links}                                                                
                                  />
                                </Box>                      
                                <Box
                                  width="fit-content"
                                  p="1rem 2rem"
                                  borderRadius="0.5rem"
                                  type="button"                          
                                  onClick={() => arrayHelpers.remove(index)}
                                  color="danger.100"
                                  mt={{md: "1.5rem"}}
                                  cursor="pointer"
                                  mx={{xs: "auto", md: "0"}}
                                >
                                  <MdOutlineDelete
                                    fontSize="2rem"
                                  />
                                </Box>                      
                              </Flex>
                            ))}
                          </Box>
                        )}
                      />  
                    </Box>  
                  </Flex>              
                  
                  <Flex justifyContent="space-between">
                    <Button
                      variant="warning"                    
                      px="2rem"
                      py="1rem"
                      type="button"                    
                      mt="1rem"                                                                                   
                      borderRadius="0.5rem"
                      width="fit-content"
                      onClick={() => {                        
                        setEditMode(false);
                        setExamForm(null);                        
                        resetForm()
                      }}
                    >
                      <Text fontSize="1.4rem" fontWeight="400">
                        Reset
                      </Text>
                    </Button>
                    <Button
                      variant="success"                    
                      px="2rem"
                      py="1rem"
                      type="submit"                    
                      mt="1rem"                                       
                      loading={loading}
                      textTransform="uppercase"
                      borderRadius="0.5rem"
                      width="fit-content"
                    >
                      <Text fontSize="1.6rem" fontWeight="500">
                        {editMode ? "Update" : "Add"} Exam Form
                      </Text>
                    </Button>                    
                  </Flex>
                </Form>
              )
            }}
          </Formik>
        </Box>        
      </Box>

      <Box
        width="100%"
        bg="white"
        p="2rem"
        borderRadius="0.5rem"
        mt="4rem"
      >        
        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="1.8rem"
            fontWeight="600"
            mb="1rem"
            textTransform="uppercase"
            color="primary.200"
          >
            Manage Exam Forms
          </Text>          
        </Flex> 

        <Flex my="2rem" 
          alignItems="start"
          flexWrap="wrap"
          justifyContent="space-between"
        >          
          {examForms.map((exam, index) => (
            <Box
              key={index}
              width={{xs: "32rem", md: "50rem"}}
              p="2rem"
              borderRadius="0.5rem"
              border="1px solid whitesmoke"
              bg={exam?.hide ? "#F9C5C5" : "#C1F9C9"}
              mr="2rem"
              mb="2rem"
              height="100%"
            >
              <Flex
                justifyContent="space-between"
              >
                <Flex alignItems="center">
                  <Box                      
                    width="1.5rem"
                    height="1.5rem"
                    borderRadius="50%"
                    bg={exam?.hide ? "danger.100" : "success.100"}
                    mr="1rem"
                  /> 
                  <Text>                  
                    {exam?.name} {" "} {exam?.year}
                  </Text>
                </Flex>
                <Flex>
                  <Box
                    fontSize="2rem"
                    color="warning.100"
                    mr="1.5rem"
                    cursor="pointer"
                    onClick={() => {
                      setExamForm(exam);
                      setEditMode(true);                       
                      window.location.href = "#form";                     
                    }}
                  >
                    <FiEdit2/>
                  </Box>
                  <Box
                    fontSize="2rem"
                    color="danger.100"
                    cursor="pointer"
                    onClick={() => {
                      setForDelete(exam);
                      setDeleteModalOpen(true);
                    }}
                  >
                    <MdDeleteOutline/>
                  </Box>
                </Flex>
              </Flex>
              <Box mt="1rem" pl="2.5rem">                              
                <Text py="0.5rem">                  
                  Start Date: {exam?.startDate ? formatDate(exam?.startDate) : "Declaring Soon"}
                </Text>
                <Text py="0.5rem">
                  End Date: {exam?.endDate ? formatDate(exam?.endDate) : "Declaring Soon"}
                </Text>                                
                {exam?.fees && <Box
                  as="table"
                  width="100%"
                  my="2rem"                  
                >
                  <Box
                    as="thead"                                        
                  >
                    <Box as="tr">
                      <Box as="th" className="border" textAlign="start">                      
                        Course                      
                      </Box>
                      <Box as="th" className="border" textAlign="start">
                        Fee
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    as="tbody"                                        
                  >
                    {exam?.fees?.map((fee, index) => (
                      <Box as="tr" key={index}>
                        <Box as="td" className="border" textAlign="start">                      
                          {fee?.name}                     
                        </Box>
                        <Box as="td" className="border" textAlign="start">
                          {printINR(fee?.fee)}
                        </Box>
                      </Box>   
                    ))}                                     
                  </Box>
                </Box>}
                {exam?.links && <Text my="1rem">Attached Links</Text>}               
                <Flex
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {exam?.links?.map((link, index) => (
                    <Box 
                      key={index}
                      bg="warning.200"
                      p="1rem 2rem"
                      width="fit-content"
                      borderRadius="0.5rem"
                      color="white"
                      mr="2rem"
                      mb="2rem"
                    >
                      <a href={link.href}>
                        <Text>
                          {link.name}
                        </Text>
                      </a>
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Box>
          ))}
        </Flex>   
      </Box>
    </>
  )
}