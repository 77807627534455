import { Layout } from "organisms";
import { ToolsPage } from "templates";

const Tools = () => {

  document.title = "Tools | ADMIN SSJU";

  return (
    <Layout>
      <ToolsPage/>
    </Layout>
  )
}

export default Tools