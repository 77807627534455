import { AiFillBook, AiOutlineSchedule, AiOutlineUserAdd } from 'react-icons/ai';
import { BiNews } from 'react-icons/bi';
import { BsCalendar2EventFill } from 'react-icons/bs';
import { FaChalkboardTeacher, FaRegAddressCard } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { GiNewspaper, GiTeacher } from 'react-icons/gi';
import { IoIosSchool } from 'react-icons/io';
import { MdOutlineDashboard, MdSportsKabaddi } from "react-icons/md";
import { RiGalleryLine } from 'react-icons/ri';

export const sideNav = [
  {
    Icon: <MdOutlineDashboard/>,
    title: "Dashboard",
    to: "/dashboard",
    description: "View Admin dashboard",
  },
  {
    Icon: <BiNews/>,
    title: "News",
    to: "/news",
    description: "Manage News",
  },
  {
    Icon: <BsCalendar2EventFill/>,
    title: "Events",
    to: "/events",
    description: "Manage Events",
  },
  {
    Icon: <MdSportsKabaddi/>,
    title: "Sports",
    to: "/sports",
    description: "Manage Sports",
  },
  {
    Icon: <GiTeacher/>,
    title: "Faculties",
    to: "/faculties",
    description: "Manage Faculties",
  },
  {
    Icon: <RiGalleryLine/>,
    title: "Gallery",
    to: "/gallery",
    description: "Manage Gallery",
  },
  {
    Icon: <AiOutlineSchedule/>,
    title: "Exam Schedule",
    to: "/exam-schedule",
    description: "Manage Exam Schedule",
  },
  {
    Icon: <GiNewspaper/>,
    title: "Entrance Exams",
    to: "/entrance-exams",
    description: "Manage Entrance Exams",
  },
  {
    Icon: <AiOutlineUserAdd/>,
    title: "Admissions",
    to: "/admissions",
    description: "Manage Admissions",
  },
  {
    Icon: <AiFillBook/>,
    title: "Entrance Syllabus",
    to: "/entrance-syllabus",
    description: "Manage Entrance Syllabus",
  },
  {
    Icon: <AiFillBook/>,
    title: "NEP Syllabus",
    to: "/nep-syllabus",
    description: "Manage NEP Syllabus",
  },
  {
    Icon: <FaRegAddressCard/>,
    title: "Exam Results",
    to: "/exam-results",
    description: "Manage Exam Results",
  },
  {
    Icon: <IoIosSchool/>,
    title: "Exam Forms",
    to: "/exam-forms",
    description: "Manage Exam Forms",
  },
  {
    Icon: <FiExternalLink/>,
    title: "Important Links",
    to: "/important-links",
    description: "Manage Important Links",
  },
  {
    Icon: <FaChalkboardTeacher/>,
    title: "Recruitments",
    to: "/recruitments",
    description: "Manage Recruitments",
  }
]