import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { MdOutlineTitle } from "react-icons/md";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, Loader, Text } from "atoms";
import { db } from "utils/firebase";
import { getFirebaseTimestamp, uploadImageToFirebase } from "utils/utilities";

const validationSchema = Yup.object({
  albumTitle: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters"),  
});

export const GalleryPage = () => {

  const [ loading, setLoading ] = useState(false);
  const [ thumbnail, setThumbnail ] = useState(null);  
  const [ images, setImages ] = useState([]);    
  const [ error, setError ] = useState(null); 
  const [ imagesPreview, setImagesPreview ] = useState([]);

  useEffect(() => {
    if(error){
      toast.error(error);
      setError(null);
    }       
  }, [loading, error]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    setLoading(true); 
    let bgImage = "";
    if(thumbnail){
      bgImage = await uploadImageToFirebase(thumbnail, "images/gallery");
    }else{
      toast.error("Thumbnail Required");
      setLoading(false);
      return;
    }
    let imagesArr = [];
    for(let i = 0; i < images.length; i++){
      let image = await uploadImageToFirebase(images[i], "images/gallery");
      imagesArr.push(image);
    }
    let album = {
      albumTitle: values.albumTitle,
      bgImage,
      images: imagesArr,
      noOfImg: imagesArr.length,
      timestamp: getFirebaseTimestamp(),
    }   
    db.collection("gallery").add(album);
    toast.success("Album Added Successfully!");
    setThumbnail(null);
    setImages([]);   
    setImagesPreview([]); 
    resetForm();
    setLoading(false);
    setSubmitting(false);          
  }

  const handleAlbumImages = (e) => {    
    if(e.target.files.length > 0){
      let imagesArr = [...images];
      let imagePreviewArr = [...imagesPreview];
      for(let i = 0; i < e.target.files.length; i++){
        imagesArr.push(e.target.files[i]);
        imagePreviewArr.push(URL.createObjectURL(e.target.files[i]));
      }
      setImages(imagesArr);
      setImagesPreview(imagePreviewArr);
    }
  }  

  return (
    <Box
      width="100%"
      bg="white"
      p="2rem"
      borderRadius="0.5rem"
    >
      {loading && <Loader />}
      <Text
        fontSize="1.8rem"
        fontWeight="600"
        mb="1rem"
        textTransform="uppercase"
        color="primary.200"
      >
        Add Album
      </Text>
      <Box my="2rem">         
        <Formik
          initialValues={{              
            albumTitle: "",            
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors }) => {
            return (                  
              <Form>  
                <Flex
                  flexDirection={{ xs: "column-reverse", md: "row" }}
                >
                  <Box flex="2">
                    <Input
                      name="albumTitle"
                      type="text"
                      label="Album Title*"                        
                      id="albumTitle"
                      icon={<MdOutlineTitle />}
                      iconColor={errors.albumTitle ? "danger.100" : "primary.200"}
                      touched={touched.albumTitle}
                      errors={errors.albumTitle}                            
                    /> 
                    <Box mt="2rem">
                      <Flex
                        flexWrap="wrap"
                      >
                        {imagesPreview && imagesPreview?.map((image, index) => (
                            <Box
                              key={index}
                              width="20rem"
                              height="15rem"
                              mr="2rem"
                              mb="2rem"
                              border="1px solid whitesmoke"
                            >
                              <Image
                                src={image}                                
                                width="100%"
                                height="100%"
                                objectFit="contain"
                                borderRadius="0.5rem"
                                alt={"album"+String(index)}                                
                              />
                            </Box>
                        ))}
                      </Flex>
                      <input                         
                        type="file"
                        accept="image/*"
                        id="images"
                        name="images"
                        style={{ display: "none" }}
                        multiple
                        onChange={handleAlbumImages}
                      />
                      <Button
                        as="label"
                        htmlFor="images"                        
                        variant="success"
                        width="fit-content"
                        ml={{ xs: "0", md: "auto" }}   
                        p="1rem 2rem"                     
                        borderRadius="0.5rem"
                      >
                        Pick Album Images
                      </Button>
                    </Box>
                  </Box>
                  <Box flex="1" ml={{md: "1rem"}} mt="1.6rem">
                    {thumbnail &&                         
                      <Image 
                        src={URL.createObjectURL(thumbnail)} 
                        width="fit-content" 
                        height="18rem" 
                        objectFit="contain"  
                        borderRadius="0.5rem"                           
                        style={{border: "2px solid whitesmoke", margin: "0 auto", marginBottom: "1rem"}}
                      />}
                    <input
                      type="file"
                      accept="image/*"
                      id="thumbnail"
                      name="thumbnail"
                      style={{ display: "none" }}
                      onChange={(e)=> setThumbnail(e.target.files[0])}
                    />
                    <Button
                      as="label"
                      htmlFor="thumbnail"
                      variant="info"
                      width="fit-content"
                      mx="auto"
                      p="1rem 2rem"
                      borderRadius="0.5rem"                       
                    >
                      Pick Thumbnail
                    </Button>
                  </Box>        
                </Flex>
                <Button
                  variant="primary"                    
                  px="2rem"
                  py="1rem"
                  type="submit"                    
                  mt="1rem"                                       
                  loading={loading}
                  textTransform="uppercase"
                  borderRadius="0.5rem"
                  width="fit-content"
                >
                  <Text fontSize="1.6rem" fontWeight="500">
                    Add Album
                  </Text>
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}