import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  faculties: [],
  entranceExams: [],
  examForms: [],
  examResults: [],
  importantLinks: [],
}

export const appReducer = (state = initState, action) => {
  switch(action.type){
    case appConstants.GET_FACULTIES_REQUEST:
    case appConstants.GET_ENTRANCE_EXAMS_REQUEST:
    case appConstants.GET_EXAM_FORMS_REQUEST:
    case appConstants.GET_EXAM_RESULTS_REQUEST:
    case appConstants.GET_IMPORTANT_LINKS_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case appConstants.GET_FACULTIES_SUCCESS:
      return {
        ...state,
        faculties: action.payload,
        loading: false,
      }

    case appConstants.GET_ENTRANCE_EXAMS_SUCCESS:
      return {
        ...state,
        entranceExams: action.payload,
        loading: false,
      }

    case appConstants.GET_EXAM_FORMS_SUCCESS:
      return {
        ...state,
        examForms: action.payload,
        loading: false,
      }

    case appConstants.GET_EXAM_RESULTS_SUCCESS:
      return {
        ...state,
        examResults: action.payload,
        loading: false,
      }

    case appConstants.GET_IMPORTANT_LINKS_SUCCESS:
      return {
        ...state,
        importantLinks: action.payload,
        loading: false,
      }

    case appConstants.GET_FACULTIES_FAILURE:
    case appConstants.GET_ENTRANCE_EXAMS_FAILURE:
    case appConstants.GET_EXAM_FORMS_FAILURE:
    case appConstants.GET_EXAM_RESULTS_FAILURE:
    case appConstants.GET_IMPORTANT_LINKS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    
    default: return state;
  }
}