import { Layout } from "organisms";
import { EventsPage } from "templates";

const Events = () => {

  document.title = "Events | ADMIN SSJU";

  return (
    <Layout>
      <EventsPage/>
    </Layout>
  )
}

export default Events