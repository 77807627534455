import { Layout } from "organisms";
import { EntranceExamsPage } from "templates";

const EntranceExams = () => {

  document.title = "Entrance Exams | ADMIN SSJU";

  return (
    <Layout>
      <EntranceExamsPage/>
    </Layout>
  )
}

export default EntranceExams