import { Layout } from "organisms";
import { SportsPage } from "templates";

const Sports = () => {

  document.title = "Sports | ADMIN SSJU";

  return (
    <Layout>
      <SportsPage/>
    </Layout>
  )
}

export default Sports