import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { Box, Flex, Text } from "atoms";

export const SidebarOption = ({
  Icon,
  title,
  to,
  description
}) => {

  const [ hover, setHover ] = useState(false);

  return (    
    <NavLink
      to={to}
      activeClassName="active"      
    >
      <Flex                   
        alignItems="center"
        justifyContent="space-between"
        mb="1rem"          
        p="0.8rem 0.5rem"
        className="sidebar-option" 
      >
        <Flex
          alignItems="center"            
        >
          <Box
            fontSize={{xs: "2.4rem", xm: "2rem"}}
            color="primary.200"
            width={{xs: "3rem", xm: "3.6rem"}}
            display="flex"
            justifyContent="center"
            alignItems="center"              
          >
            {Icon}
          </Box>
          <Text
            fontSize={{xs: "1.2rem", md: "1.6rem"}}
            fontWeight="500"
            color="grey.300"              
            display={{xs: "none", xm: "block"}}
          >
            {title}
          </Text>
        </Flex>
        <Box
          fontSize="1.4rem"
          color="grey.300"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          pt="0.3rem"
          pr="0.4rem"
          display={{xs: "none", xm: "block"}}
        >
          <AiOutlineInfoCircle/>
        </Box>          
        <Box
          display={hover ? "block" : "none"}
          position="absolute"
          top="90%"
          right="2%"
          color="white"
          border="1px solid"
          borderColor="whitesmoke"
          borderRadius="0.4rem"
          p="0.8rem"
          fontSize="1.2rem"
          bg="rgba(0, 0, 0, 0.8)"
          zIndex="199"          
        >
          {hover && description}
        </Box>          
      </Flex>    
    </NavLink>              
  )
}
