import { Layout } from "organisms";
import { ExamFormsPage } from "templates";

const ExamForms = () => {

  document.title = "Exam Forms | ADMIN SSJU";

  return (
    <Layout>
      <ExamFormsPage/>
    </Layout>
  )
}

export default ExamForms